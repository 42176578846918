import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  fetchCategoryList,
  addCategory,
  updateCategory,
  deleteCategory,
  changeOrder
} from '../services/CategoryService';

const initialState = {
  status: 'idle',
  categoryList: [],
  categoryListFilter: [],
  availableCategoryList: [],
  availableCategoryListFilter: [],
  currentCategory: {
    _id: '',
    label: 'Root'
  },
};

export const fetchListAsync = createAsyncThunk(
  'category/fetchList',
  async (parentId) => {
    const response = await fetchCategoryList({ parent: parentId });
    return response.data;
  }
);
export const fetchAvailableListAsync = createAsyncThunk(
  'category/fetchAvailableList',
  async () => {
    const response = await fetchCategoryList({ isAvailable: true, isActive: true });
    return response.data;
  }
);

export const categorySlice = createSlice({
  name: 'category',
  initialState,
  reducers: {
    changeCurrentCategory: (state, action) => {
      state.currentCategory = {
        ...state.currentCategory,
        _id: action.payload._id,
        label: action.payload.label
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchListAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchListAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        
        state.categoryList = [...action.payload.data];

        const updatedCategoryListFilter = action.payload.data.map((each) => ({
          value: each._id,
          label: each.name
        }));
        state.categoryListFilter = [{ value: '', label: 'Please choose ...' }, ...updatedCategoryListFilter];
      })
      .addCase(fetchListAsync.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      })
      .addCase(fetchAvailableListAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchAvailableListAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        
        state.availableCategoryList = [...action.payload.data];
      });;
  },
});

export const { changeCurrentCategory } = categorySlice.actions;

export const getCurrentCategory = (state) => state.category.currentCategory;
export const getCategoryList = (state) => state.category.categoryList;
export const getCategoryListFilter = (state) => state.category.categoryListFilter;
export const getAvailableCategoryList = (state) => state.category.availableCategoryList;

export default categorySlice.reducer;
