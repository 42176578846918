import http from "../utils/http-common";

const token = localStorage.getItem("accessToken");

export const fetchCategoryList = (searchInfo) => {
  return http
    .post('api/v1/category/list', searchInfo, {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${token}`
      },
    });
}

export const addCategory = (categoryInfo) => {
  return http.post('/api/v1/category', categoryInfo, {
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${token}`
    },
  });
}

export const updateCategory = (categoryInfo) => {
  return http.put(`/api/v1/category/${categoryInfo._id}`, categoryInfo, {
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${token}`
    },
  });
}

export const deleteCategory = (id) => {
  return http.delete(`/api/v1/category/${id}`, {
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${token}`
    },
  });
}

export const changeOrder = (changeInfo) => {
  return http
    .post('api/v1/category/change_order', changeInfo, {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${token}`
      },
    });
}
