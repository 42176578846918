import http from "../utils/http-common";

const token = localStorage.getItem("accessToken");

export const uploadFile = (file, onUploadProgress) => {
  let formData = new FormData();

  formData.append("file", file);

  return http.post("/upload", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress,
  });
};

export const deleteFile = (name) => {
  return http.delete(`/files/${name}`, {
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${token}`
    },
  });
};
