import React, { useState, useEffect, useContext } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import { findUpper } from "../../../utils/Utils";
import { userData, filterRole, filterStatus, countryOptions } from "./UserData";
import moment from "moment";
import swal from "sweetalert";

import {
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  UncontrolledDropdown,
  Modal,
  ModalBody,
  DropdownItem,
  Form,
} from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Row,
  Col,
  UserAvatar,
  PaginationComponent,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableRow,
  DataTableItem,
  Button,
  RSelect,
  TooltipComponent,
} from "../../../components/Component";
import Select from "react-select";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { UserContext } from "./UserContext";
import { bulkActionOptions } from "../../../utils/Utils";
import axios from "axios";
import { API_BASE_URL } from "../../../utils/Utils";

const UserListCompact = () => {
  const { contextData } = useContext(UserContext);
  const [data, setData] = contextData;

  const [sm, updateSm] = useState(false);
  const [tablesm, updateTableSm] = useState(false);
  const [onSearch, setonSearch] = useState(true);
  const [onSearchText, setSearchText] = useState("");
  const [modal, setModal] = useState({
    edit: false,
    add: false,
    view: false,
  });
  const [editId, setEditedId] = useState();
  const [formData, setFormData] = useState({
    _id: "",
    email: "",
    phone: "",
    salutation: "",
    first_name: "",
    middle_name: "",
    last_name: "",
    address: "",
    role: "",
    country_code: "",
    status: { value: 1, label: "Active" },
    createdAt: "",
  });
  const [actionText, setActionText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(10);
  const [sort, setSortState] = useState("");
  const [loading, setLoading] = useState(false);
  const [submittedData, setSubmitData] = useState(null);
  const [errorVal, setError] = useState("");
  const [userList, setUserList] = useState([]);
  const [quote, setQuote] = useState([]);
  const [token, setToken] = useState(localStorage.getItem("accessToken"));

  // Sorting data
  const sortFunc = (params) => {
    let defaultData = userList;
    if (params === "asc") {
      let sortedData = defaultData.sort((a, b) => a.name.localeCompare(b.name));
      setUserList([...sortedData]);
    } else if (params === "dsc") {
      let sortedData = defaultData.sort((a, b) => b.name.localeCompare(a.name));
      setUserList([...sortedData]);
    }
  };

  // unselects the data on mount
  useEffect(() => {
    let newData;
    newData = userData.map((item) => {
      item.checked = false;
      return item;
    });
    setUserList([...newData]);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Get user data

  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/api/v1/user`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setUserList(response.data.data);
        setData(response.data.data);
      });
    // Your code here
  }, []);

  // Changing state value when searching name
  useEffect(() => {
    if (onSearchText !== "") {
      console.log(data);
      const filteredObject = userList.filter((item) => {
        return item.email.toLowerCase().includes(onSearchText.toLowerCase());
      });
      console.log(filteredObject);
      setUserList([...filteredObject]);
    } else {
      setUserList([...data]);
    }
  }, [onSearchText, setData]);

  // onChange function for searching name
  const onFilterChange = (e) => {
    setSearchText(e.target.value);
  };

  // function to reset the form
  const resetForm = () => {
    setFormData({
      _id: "",
      email: "",
      phone: "",
      salutation: "",
      first_name: "",
      middle_name: "",
      last_name: "",
      address: "",
      role: "",
      status: 1,
      country_code: "",
      createdAt: "",
    });
  };

  // function to close the form modal
  const onFormCancel = () => {
    setModal({ edit: false, add: false });
    resetForm();
  };

  // submit function to update a new item
  const onEditSubmit = (submitData) => {
    setLoading(true);
    // const { name, email, phone } = submitData;
    let submittedData;
    let newitems = userList;
    newitems.forEach((item) => {
      if (item._id === editId) {
        submittedData = {
          salutation: formData.salutation,
          first_name: submitData.first_name,
          last_name: submitData.last_name,
          email: submitData.email,
          country_code: formData.country_code,
          phone: submitData.phone,
          role: formData.role,
        };
      }
    });
    console.log(submittedData);
    axios
      .put(`${API_BASE_URL}/api/v1/user/${editId}`, submittedData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        console.log(response);
        setModal({ edit: false });
        // window.location.reload(false);
        resetForm();
        axios
          .get(`${API_BASE_URL}/api/v1/user`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((response) => {
            setUserList(response.data.data);
            setData(response.data.data);
            console.log(response.data.data);
          });
        swal("Success", "User updated successfully", "success");
      })
      .catch(function (error) {
        swal("Opps!", "An error has occured", "warning");
        console.log(error.message);
        setTimeout(() => {
          setError(error.message);
          setLoading(false);
        }, 2000);
      });
  };

  // function that loads the want to editted data
  const onAddSubmit = (submitData) => {
    console.log("add", submitData);
    setLoading(true);
    // setModal({ add: false });

    let submittedData = {
      salutation: formData.salutation,
      first_name: submitData.first_name,
      last_name: submitData.last_name,
      email: submitData.email,
      country_code: formData.country_code,
      phone: submitData.phone,
      role: formData.role,
    };

    console.log("submit data", submittedData);

    axios
      .post(`${API_BASE_URL}/api/v1/user`, submittedData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        console.log(response);
        setModal({ add: false });
        // window.location.reload(false);

        axios
          .get(`${API_BASE_URL}/api/v1/user`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((response) => {
            setUserList(response.data.data);
            setData(response.data.data);
            console.log(response.data.data);
          });
        swal("Good job!", "User added successfully", "success");
      })
      .catch(function (error) {
        setModal({ add: false });
        swal("Opps!", "An error has occured", "warning");
        console.log(error.message);
        setTimeout(() => {
          setError(error.message);
          setLoading(false);
        }, 2000);
      });
  };

  // function that loads the want to editted data
  const onEditClick = (id) => {
    console.log("edit", id);
    userList.forEach((item) => {
      if (item._id === id) {
        setFormData({
          _id: item._id,
          email: item.email,
          phone: item.phone,
          salutation: item.salutation,
          first_name: item.first_name,
          middle_name: item.middle_name,
          last_name: item.last_name,
          address: item.address,
          role: item.role,
          status: item.status,
          country_code: item.country_code,
          createdAt: item.createdAt,
        });
        setModal({ edit: true }, { add: false });
        setEditedId(item._id);
      }
    });
  };

  const onViewClick = (id) => {
    console.log("id view", id);
    userList.forEach((item) => {
      if (item._id === id) {
        setFormData({
          _id: item._id,
          email: item.email,
          phone: item.phone,
          salutation: item.salutation,
          first_name: item.first_name,
          middle_name: item.middle_name,
          last_name: item.last_name,
          address: item.address,
          role: item.role,
          status: item.status,
          country_code: item.country_code,
          createdAt: item.createdAt,
        });
        setModal({ view: true }, { add: false });
        setEditedId(item._id);
      }
    });
  };

  // function which fires on applying selected action
  const onActionClick = (id) => {
    console.log("delete", id);
    axios
      .delete(`${API_BASE_URL}/api/v1/user/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        axios
          .get(`${API_BASE_URL}/api/v1/user`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((response) => {
            setUserList(response.data.data);
            setData(response.data.data);
            console.log(response.data.data);
          });
        swal("Success", "User deleted successfully", "success");
      });
  };

  // function which selects all the items
  const selectorCheck = (e) => {
    let newData;
    newData = userList.map((item) => {
      item.checked = e.currentTarget.checked;
      return item;
    });
    setData([...newData]);
  };

  // function to toggle the search option
  const toggle = () => setonSearch(!onSearch);

  const { errors, register, handleSubmit } = useForm();

  // Get current list, pagination
  const indexOfLastItem = currentPage * itemPerPage;
  const indexOfFirstItem = indexOfLastItem - itemPerPage;
  const currentItems = userList.slice(indexOfFirstItem, indexOfLastItem);

  // Change Page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <React.Fragment>
      <Head title="User List - Compact"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h3" page>
                Users Lists
              </BlockTitle>
              <BlockDes className="text-soft">
                <p>You have total {userList.length} users.</p>
              </BlockDes>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>

        <Block>
          <DataTable className="card-stretch">
            <div className="card-inner position-relative card-tools-toggle">
              <div className="card-title-group">
                <div className="card-tools mr-n1">
                  <ul className="btn-toolbar gx-1">
                    <li>
                      <a
                        href="#search"
                        onClick={(ev) => {
                          ev.preventDefault();
                          toggle();
                        }}
                        className="btn btn-icon search-toggle toggle-search"
                      >
                        <Icon name="search"></Icon>
                      </a>
                    </li>
                    <li className="btn-toolbar-sep"></li>
                    <button className="btn btn-primary" onClick={() => setModal({ add: true })}>
                      Add New User
                    </button>
                  </ul>
                </div>
              </div>
              <div className={`card-search search-wrap ${!onSearch && "active"}`}>
                <div className="card-body">
                  <div className="search-content">
                    <Button
                      className="search-back btn-icon toggle-search active"
                      onClick={() => {
                        setSearchText("");
                        toggle();
                      }}
                    >
                      <Icon name="arrow-left"></Icon>
                    </Button>
                    <input
                      type="text"
                      className="border-transparent form-focus-none form-control"
                      placeholder="Search by email"
                      value={onSearchText}
                      onChange={(e) => onFilterChange(e)}
                    />
                    <Button className="search-submit btn-icon">
                      <Icon name="search"></Icon>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <DataTableBody compact>
              <DataTableHead>
                {/* <DataTableRow className="nk-tb-col-check">
                  <div className="custom-control custom-control-sm custom-checkbox notext">
                    <input
                      type="checkbox"
                      className="custom-control-input form-control"
                      onChange={(e) => selectorCheck(e)}
                      id="uid"
                    />
                    <label className="custom-control-label" htmlFor="uid"></label>
                  </div>
                </DataTableRow> */}

                <DataTableRow size="lg">
                  <span className="sub-text">User ID</span>
                </DataTableRow>
                <DataTableRow size="lg">
                  <span className="sub-text">Email</span>
                </DataTableRow>
                <DataTableRow size="lg">
                  <span className="sub-text">Phone</span>
                </DataTableRow>
                <DataTableRow size="lg">
                  <span className="sub-text">Full-Name</span>
                </DataTableRow>
                {/* <DataTableRow size="lg">
                  <span className="sub-text">Address</span>
                </DataTableRow> */}
                <DataTableRow size="lg">
                  <span className="sub-text">Role</span>
                </DataTableRow>
                {/* <DataTableRow size="lg">
                  <span className="sub-text">Status</span>
                </DataTableRow> */}
                <DataTableRow size="lg">
                  <span className="sub-text">Date</span>
                </DataTableRow>
                <DataTableRow className="nk-tb-col-tools text-right"></DataTableRow>
              </DataTableHead>
              {/*Head*/}
              {currentItems.length > 0
                ? currentItems.map((item) => {
                    var Fullname = item?.salutation + " " + item?.first_name + " " + item?.last_name;
                    return (
                      <DataTableItem key={item._id}>
                        <DataTableRow size="lg">
                          <span>{item._id}</span>
                        </DataTableRow>
                        <DataTableRow size="lg">
                          <span>{item.email}</span>
                        </DataTableRow>
                        <DataTableRow size="lg">
                          <span>{item?.country_code + " " + item?.phone}</span>
                        </DataTableRow>
                        <DataTableRow size="lg">
                          <span>{Fullname}</span>
                        </DataTableRow>
                        {/* <DataTableRow size="lg">
                          <span>{item.address}</span>
                        </DataTableRow> */}
                        <DataTableRow size="lg">
                          <span>{item?.role}</span>
                        </DataTableRow>
                        {/* <DataTableRow size="lg">
                          <span>{item.status}</span>
                        </DataTableRow> */}

                        <DataTableRow size="lg">
                          <span>{moment(item.createdAt).format("DD/MM/YY")}</span>
                        </DataTableRow>
                        <DataTableRow className="nk-tb-col-tools">
                          <ul className="nk-tb-actions gx-1">
                            <li>
                              <UncontrolledDropdown>
                                <DropdownToggle tag="a" className="dropdown-toggle btn btn-icon btn-trigger">
                                  <Icon name="more-h"></Icon>
                                </DropdownToggle>
                                <DropdownMenu right>
                                  <ul className="link-list-opt no-bdr">
                                    <li onClick={() => onViewClick(item._id)}>
                                      <DropdownItem
                                        tag="a"
                                        href="#view"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                        }}
                                      >
                                        <Icon name="eye"></Icon>
                                        <span>View</span>
                                      </DropdownItem>
                                    </li>
                                    <li onClick={() => onEditClick(item._id)}>
                                      <DropdownItem
                                        tag="a"
                                        href="#edit"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                        }}
                                      >
                                        <Icon name="edit"></Icon>
                                        <span>Edit</span>
                                      </DropdownItem>
                                    </li>
                                    <li onClick={() => onActionClick(item._id)}>
                                      <DropdownItem
                                        tag="a"
                                        href="#delete"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                        }}
                                      >
                                        <Icon name="delete"></Icon>
                                        <span>Delete </span>
                                      </DropdownItem>
                                    </li>
                                  </ul>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </li>
                          </ul>
                        </DataTableRow>
                      </DataTableItem>
                    );
                  })
                : null}
            </DataTableBody>
            <div className="card-inner">
              {currentItems.length > 0 ? (
                <PaginationComponent
                  itemPerPage={itemPerPage}
                  totalItems={userList.length}
                  paginate={paginate}
                  currentPage={currentPage}
                />
              ) : (
                <div className="text-center">
                  <span className="text-silent">No data found</span>
                </div>
              )}
            </div>
          </DataTable>
        </Block>
        {/* -----------------------user details view modal-------------------------- */}
        <Modal isOpen={modal.view} toggle={() => setModal({ view: false })} className="modal-dialog-centered" size="lg">
          <ModalBody>
            <a
              href="#cancel"
              onClick={(ev) => {
                ev.preventDefault();
                onFormCancel();
              }}
              className="close"
            >
              <Icon name="cross-sm"></Icon>
            </a>
            <div className="p-2">
              <h5 className="title">User Details</h5>
              <div className="mt-4">
                <Form className="row gy-4">
                  <input type="hidden" value={formData._id} name="_id" />
                  <Col md="4">
                    <FormGroup>
                      <label className="form-label">Salutation</label>
                      <p>{formData?.salutation}</p>
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup>
                      <label className="form-label">First Name</label>
                      <p>{formData?.first_name}</p>
                    </FormGroup>
                  </Col>
                  {/* <Col md="4">
                    <FormGroup>
                      <label className="form-label">Middle Name</label>
                      <p>{formData.middle_name}</p>
                    </FormGroup>
                  </Col> */}
                  <Col md="4">
                    <FormGroup>
                      <label className="form-label">Last Name</label>
                      <p>{formData?.last_name}</p>
                    </FormGroup>
                  </Col>

                  <Col md="12">
                    <FormGroup>
                      <label className="form-label">Email</label>
                      <p>{formData?.email}</p>
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <label className="form-label">Country Code</label>
                      <p>{formData?.country_code}</p>
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <label className="form-label">Phone</label>
                      <p>{formData?.phone}</p>
                    </FormGroup>
                  </Col>
                  {/* <Col md="12">
                    <FormGroup>
                      <label className="form-label">Address</label>
                      <p>{formData.address}</p>
                    </FormGroup>
                  </Col> */}
                  <Col size="12">
                    <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                      <li>
                        <a
                          href="#cancel"
                          onClick={(ev) => {
                            ev.preventDefault();
                            onFormCancel();
                          }}
                          className="btn btn-primary"
                        >
                          Close
                        </a>
                      </li>
                    </ul>
                  </Col>
                </Form>
              </div>
            </div>
          </ModalBody>
        </Modal>
        {/* ----------------------user details edit modal------------------------------------- */}
        <Modal isOpen={modal.edit} toggle={() => setModal({ edit: false })} className="modal-dialog-centered" size="lg">
          <ModalBody>
            <a
              href="#cancel"
              onClick={(ev) => {
                ev.preventDefault();
                onFormCancel();
              }}
              className="close"
            >
              <Icon name="cross-sm"></Icon>
            </a>
            <div className="p-2">
              <h5 className="title">Update User</h5>
              <div className="mt-4">
                <Form className="row gy-4" onSubmit={handleSubmit(onEditSubmit)}>
                  <input type="hidden" value={formData._id} name="_id" />
                  <Col md="4">
                    <FormGroup>
                      <label className="form-label">Salutation</label>
                      <div className="form-control-wrap">
                        <RSelect
                          name="salutaton"
                          options={filterStatus}
                          defaultValue={{ value: formData.salutation, label: formData.salutation }}
                          onChange={(e) => setFormData({ ...formData, salutation: e.value })}
                        />
                      </div>
                      {errors.name && <span className="invalid">{errors.name.message}</span>}
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup>
                      <label className="form-label">First Name</label>
                      <input
                        className="form-control"
                        type="text"
                        name="first_name"
                        defaultValue={formData.first_name}
                        placeholder="First Name"
                        ref={register({ required: "This field is required" })}
                      />
                      {errors.first_name && <span className="invalid">{errors.first_name.message}</span>}
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup>
                      <label className="form-label">Last Name</label>
                      <input
                        className="form-control"
                        type="text"
                        name="last_name"
                        defaultValue={formData.last_name}
                        placeholder="Last Name"
                        ref={register({ required: "This field is required" })}
                      />
                      {errors.last_name && <span className="invalid">{errors.last_name.message}</span>}
                    </FormGroup>
                  </Col>
                  <Col md="12">
                    <FormGroup>
                      <label className="form-label">Email</label>
                      <input
                        className="form-control"
                        type="text"
                        name="email"
                        defaultValue={formData.email}
                        placeholder="Email"
                        ref={register({
                          required: "This field is required",
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: "invalid email address",
                          },
                        })}
                      />
                      {errors.email && <span className="invalid">{errors.email.message}</span>}
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup>
                      <label className="form-label">Country Code</label>
                      <div className="form-control-wrap">
                        <RSelect
                          name="country_code"
                          options={countryOptions}
                          defaultValue={{ value: formData.country_code, label: formData.country_code }}
                          onChange={(e) => setFormData({ ...formData, country_code: e.value })}
                          ref={register({})}
                        />
                      </div>
                      {errors.name && <span className="invalid">{errors.name.message}</span>}
                    </FormGroup>
                  </Col>
                  <Col md="8">
                    <FormGroup>
                      <label className="form-label">Phone No</label>
                      <input
                        className="form-control"
                        type="text"
                        name="phone"
                        defaultValue={formData.phone}
                        placeholder="Phone No"
                        ref={register({ required: "This field is required" })}
                      />
                      {errors.phone && <span className="invalid">{errors.phone.message}</span>}
                    </FormGroup>
                  </Col>
                  <Col md="8">
                    <FormGroup>
                      <label className="form-label">User's Role</label>
                      <div className="form-control-wrap">
                        <RSelect
                          name="role"
                          options={filterRole}
                          defaultValue={{ value: formData.role, label: formData.role }}
                          onChange={(e) => setFormData({ ...formData, role: e.value })}
                          ref={register({})}
                        />
                      </div>
                      {errors.name && <span className="invalid">{errors.name.message}</span>}
                    </FormGroup>
                    {/* <FormGroup>
                      <label className="form-label">Role</label>
                      <input
                        className="form-control"
                        type="text"
                        name="role"
                        defaultValue={formData.role}
                        placeholder="Role"
                        ref={register({ required: "This field is required" })}
                      />
                      {errors.role && <span className="invalid">{errors.role.message}</span>}
                    </FormGroup> */}
                  </Col>
                  <Col size="12">
                    <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                      <li>
                        <Button color="primary" size="md" type="submit">
                          Update User
                        </Button>
                      </li>
                      <li>
                        <a
                          href="#cancel"
                          onClick={(ev) => {
                            ev.preventDefault();
                            onFormCancel();
                          }}
                          className="link link-light"
                        >
                          Cancel
                        </a>
                      </li>
                    </ul>
                  </Col>
                </Form>
              </div>
            </div>
          </ModalBody>
        </Modal>

        {/* ----------------------add new user modal------------------------------------- */}
        <Modal isOpen={modal.add} toggle={() => setModal({ add: false })} className="modal-dialog-centered" size="lg">
          <ModalBody>
            <a
              href="#cancel"
              onClick={(ev) => {
                ev.preventDefault();
                onFormCancel();
              }}
              className="close"
            >
              <Icon name="cross-sm"></Icon>
            </a>
            <div className="p-2">
              <h5 className="title">Add New User</h5>
              <div className="mt-4">
                <Form className="row gy-4" onSubmit={handleSubmit(onAddSubmit)}>
                  <Col md="4">
                    <FormGroup>
                      <label className="form-label">Salutation</label>
                      <div className="form-control-wrap">
                        <RSelect
                          name="salutaton"
                          placeholder="Mr. or Mrs."
                          options={filterStatus}
                          onChange={(e) => setFormData({ ...formData, salutation: e.value })}
                        />
                      </div>
                      {errors.name && <span className="invalid">{errors.name.message}</span>}
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup>
                      <label className="form-label">First Name</label>
                      <input
                        className="form-control"
                        type="text"
                        name="first_name"
                        defaultValue={formData.first_name}
                        placeholder="First Name"
                        ref={register({ required: "This field is required" })}
                      />
                      {errors.first_name && <span className="invalid">{errors.first_name.message}</span>}
                    </FormGroup>
                  </Col>
                  {/* <Col md="4">
                    <FormGroup>
                      <label className="form-label">Middle Name</label>
                      <input
                        className="form-control"
                        type="text"
                        name="middle_name"
                        defaultValue={formData.middle_name}
                        placeholder="Last Name"
                        ref={register({ required: "This field is required" })}
                      />
                    </FormGroup>
                  </Col> */}
                  <Col md="4">
                    <FormGroup>
                      <label className="form-label">Last Name</label>
                      <input
                        className="form-control"
                        type="text"
                        name="last_name"
                        defaultValue={formData.last_name}
                        placeholder="Last Name"
                        ref={register({ required: "This field is required" })}
                      />
                      {errors.last_name && <span className="invalid">{errors.last_name.message}</span>}
                    </FormGroup>
                  </Col>
                  <Col md="12">
                    <FormGroup>
                      <label className="form-label">Email</label>
                      <input
                        className="form-control"
                        type="text"
                        name="email"
                        defaultValue={formData.email}
                        placeholder="Email"
                        ref={register({
                          required: "This field is required",
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: "invalid email address",
                          },
                        })}
                      />
                      {errors.email && <span className="invalid">{errors.email.message}</span>}
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup>
                      <label className="form-label">Country Code</label>
                      <div className="form-control-wrap">
                        <RSelect
                          name="country_code"
                          options={countryOptions}
                          onChange={(e) => setFormData({ ...formData, country_code: e.value })}
                        />
                      </div>
                    </FormGroup>
                  </Col>
                  <Col md="8">
                    <FormGroup>
                      <label className="form-label">Phone No</label>
                      <input
                        className="form-control"
                        type="text"
                        name="phone"
                        defaultValue={formData.phone}
                        placeholder="Phone No"
                        ref={register({ required: "This field is required" })}
                      />
                      {errors.phone && <span className="invalid">{errors.phone.message}</span>}
                    </FormGroup>
                  </Col>
                  {/* <Col md="12">
                    <FormGroup>
                      <label className="form-label">Address</label>
                      <input
                        className="form-control"
                        type="text"
                        name="address"
                        defaultValue={formData.address}
                        placeholder="Address"
                        ref={register({ required: "This field is required" })}
                      />
                      {errors.address && <span className="invalid">{errors.address.message}</span>}
                    </FormGroup>
                  </Col> */}
                  <Col md="12">
                    <FormGroup>
                      <label className="form-label">User's Role</label>
                      <div className="form-control-wrap">
                        <RSelect
                          name="role"
                          options={filterRole}
                          onChange={(e) => setFormData({ ...formData, role: e.value })}
                        />
                      </div>
                      {errors.name && <span className="invalid">{errors.name.message}</span>}
                    </FormGroup>
                    {/* <FormGroup>
                      <label className="form-label">User's Role</label>
                      <input
                        className="form-control"
                        type="text"
                        name="role"
                        defaultValue={formData.role}
                        placeholder="User's role"
                        ref={register({ required: "This field is required" })}
                      />
                      {errors.role && <span className="invalid">{errors.role.message}</span>}
                    </FormGroup> */}
                  </Col>

                  <Col size="12">
                    <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                      <li>
                        <Button color="primary" size="md" type="submit">
                          Add User
                        </Button>
                      </li>
                      <li>
                        <a
                          href="#cancel"
                          onClick={(ev) => {
                            ev.preventDefault();
                            onFormCancel();
                          }}
                          className="link link-light"
                        >
                          Cancel
                        </a>
                      </li>
                    </ul>
                  </Col>
                </Form>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </Content>
    </React.Fragment>
  );
};
export default UserListCompact;
