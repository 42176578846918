import React, { useState, useEffect, useContext } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import { findUpper } from "../../../utils/Utils";
import { userData, filterRole, ActionStatus, filterStatus, countryOptions, countryListOptions } from "./UserData";
import moment from "moment";
import swal from "sweetalert";

import {
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  UncontrolledDropdown,
  Modal,
  ModalBody,
  DropdownItem,
  Form,
} from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Row,
  Col,
  UserAvatar,
  PaginationComponent,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableRow,
  DataTableItem,
  Button,
  RSelect,
  TooltipComponent,
} from "../../../components/Component";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { UserContext } from "./UserContext";
import { bulkActionOptions } from "../../../utils/Utils";
import axios from "axios";
import { API_BASE_URL } from "../../../utils/Utils";

const UserQuots = () => {
  const { contextData } = useContext(UserContext);
  const [data, setData] = contextData;

  const [sm, updateSm] = useState(false);
  const [tablesm, updateTableSm] = useState(false);
  const [onSearch, setonSearch] = useState(true);
  const [onSearchText, setSearchText] = useState("");
  const [modal, setModal] = useState({
    edit: false,
    add: false,
    view: false,
  });
  const [editId, setEditedId] = useState();
  const [formData, setFormData] = useState({
    _id: "",
    user: {
      _id: "",
      email: "",
      phone: "",
      country_code: "",
      salutation: "",
      first_name: "",
      middle_name: "",
      last_name: "",
      address: "",
      role: "",
      status: "",
    },
    treatment_info: "",
    source_country: "",
    destination_country: "",
    remarks: "",
    specialty: "",
    status: "",
    createdAt: "",
    date: "",
  });
  const [actionText, setActionText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(10);
  const [sort, setSortState] = useState("");
  const [loading, setLoading] = useState(false);
  const [submittedData, setSubmitData] = useState(null);
  const [errorVal, setError] = useState("");
  const [quoteList, setQuoteList] = useState([]);
  const [token, setToken] = useState(localStorage.getItem("accessToken"));
  const [username, setUserName] = useState([]);

  // Sorting data
  const sortFunc = (params) => {
    let defaultData = data;
    if (params === "asc") {
      let sortedData = defaultData.sort((a, b) => a.name.localeCompare(b.name));
      setQuoteList([...sortedData]);
    } else if (params === "dsc") {
      let sortedData = defaultData.sort((a, b) => b.name.localeCompare(a.name));
      setQuoteList([...sortedData]);
    }
  };

  // Get user data

  useEffect(() => {
    let newtabelData;
    axios
      .get(`${API_BASE_URL}/api/v1/quote`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setQuoteList(response.data.data);
        setData(response.data.data);
        // console.log(response.data.data.length);
      });
    // Your code here
  }, []);

  // Changing state value when searching name
  useEffect(() => {
    if (onSearchText !== "") {
      console.log(data);
      const filteredObject = quoteList.filter((item) => {
        return (
          item._id.toLowerCase().includes(onSearchText.toLowerCase()) ||
          item.source_country.toLowerCase().includes(onSearchText.toLowerCase()) ||
          item.destination_country.toLowerCase().includes(onSearchText.toLowerCase()) ||
          item.status.toLowerCase().includes(onSearchText.toLowerCase())
        );
      });
      setQuoteList([...filteredObject]);
    } else {
      setQuoteList([...data]);
    }
  }, [onSearchText, setData]);

  // onChange function for searching name
  const onFilterChange = (e) => {
    setSearchText(e.target.value);
  };

  // function to change the selected property of an item
  const onSelectChange = (e, id) => {
    let newData = data;
    let index = newData.findIndex((item) => item.id === id);
    newData[index].checked = e.currentTarget.checked;
    setQuoteList([...newData]);
  };

  // function to set the action to be taken in table header
  const onActionText = (e) => {
    setActionText(e.value);
  };

  // function to reset the form
  const resetForm = () => {
    setFormData({
      _id: "",
      user: {
        _id: "",
        email: "",
        phone: "",
        country_code: "",
        salutation: "",
        first_name: "",
        middle_name: "",
        last_name: "",
        address: "",
        role: "",
        status: "",
      },
      treatment_info: "",
      source_country: "",
      destination_country: "",
      remarks: "",
      specialty: "",
      status: "",
      createdAt: "",
      date: "",
    });
  };

  // function to close the form modal
  const onFormCancel = () => {
    setModal({ edit: false, add: false, view: false });
    resetForm();
  };

  // submit function to add a new quote
  const onFormSubmit = (submitData) => {
    // preventDefault();
    console.log("submitted", submitData);
    // setLoading(true);
    const submittedData = {
      salutation: formData.salutation,
      first_name: submitData.first_name,
      middle_name: submitData.middle_name,
      last_name: submitData.last_name,
      email: submitData.email,
      phone: submitData.phone,
      country_code: formData.country_code,
      treatment_info: submitData.treatment_info,
      source_country: formData.source_country,
      destination_country: formData.destination_country,
      remarks: submitData.remarks,
      specialty: submitData.specialty,
    };

    axios
      .post(`${API_BASE_URL}/api/v1/quote`, submittedData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        console.log(response);
        setModal({ add: false });
        // window.location.reload(false);
        axios
          .get(`${API_BASE_URL}/api/v1/quote`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((response) => {
            setQuoteList(response.data.data);
            setData(response.data.data);
            console.log(response.data.data);
          });
        swal("Good job!", "Quote added successfully", "success");
      })
      .catch(function (error) {
        console.log(error.message);
        swal("Opps!", "An error has occured", "warning");
        setTimeout(() => {
          setError(error.message);
          setLoading(false);
        }, 2000);
      });
  };

  // submit function to update a new item
  const onEditSubmit = (submitData) => {
    setLoading(true);
    // const { name, email, phone } = submitData;
    let submittedData;
    let newitems = quoteList;
    newitems.forEach((item) => {
      if (item._id === editId) {
        submittedData = {
          treatment_info: submitData.treatment_info,
          source_country: formData.source_country,
          destination_country: formData.destination_country,
          specialty: submitData.specialty,
          remarks: submitData.remarks,
          status: formData.status,
        };
      }
    });
    console.log(submittedData);
    axios
      .put(`${API_BASE_URL}/api/v1/quote/${editId}`, submittedData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        console.log(response);
        setModal({ edit: false });
        // window.location.reload(false);
        swal("Good job!", "Quote updated successfully", "success");
        axios
          .get(`${API_BASE_URL}/api/v1/quote`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((response) => {
            setQuoteList(response.data.data);
            setData(response.data.data);
            console.log(response.data.data.length);
          });
      })
      .catch(function (error) {
        swal("Opps!", "An error has occured", "warning");
        console.log(error.message);
        setTimeout(() => {
          setError(error.message);
          setLoading(false);
        }, 2000);
      });
  };

  // function that loads the want to editted data
  const onEditClick = (id) => {
    quoteList.forEach((item) => {
      if (item._id === id) {
        setFormData({
          _id: item._id,
          user: {
            _id: item.user._id,
            email: item.user.email,
            phone: item.user.phone,
            salutation: item.user.salutation,
            first_name: item.user.first_name,
            middle_name: item.user.middle_name,
            last_name: item.user.last_name,
            address: item.user.address,
            role: item.user.role,
            status: item.user.status,
            country_code: item.user.country_code,
            createdAt: item.user.createdAt,
          },
          treatment_info: item.treatment_info,
          source_country: item.source_country,
          destination_country: item.destination_country,
          remarks: item.remarks,
          specialty: item.specialty,
          status: item.status,
          createdAt: item.createdAt,
        });
        setModal({ edit: true }, { add: false });
        setEditedId(item._id);
      }
    });
  };

  const onViewClick = (id) => {
    console.log("id view", id);
    quoteList.forEach((item) => {
      if (item._id === id) {
        setFormData({
          _id: item._id,
          user: {
            _id: item.user._id,
            email: item.user.email,
            phone: item.user.phone,
            salutation: item.user.salutation,
            first_name: item.user.first_name,
            middle_name: item.user.middle_name,
            last_name: item.user.last_name,
            address: item.user.address,
            role: item.user.role,
            status: item.user.status,
            country_code: item.user.country_code,
            createdAt: item.user.createdAt,
          },
          treatment_info: item.treatment_info,
          source_country: item.source_country,
          destination_country: item.destination_country,
          specialty: item.specialty,
          remarks: item.remarks,
          status: item.status,
          createdAt: item.createdAt,
          date: item.date,
        });
        setModal({ view: true }, { add: false });
        setEditedId(item._id);
      }
    });
  };

  // delete a quote
  const onDeleteClick = (id) => {
    console.log("delete", id);
    axios
      .delete(`${API_BASE_URL}/api/v1/quote/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        swal("Good job!", "Quote deleled successfully", "success");
        axios
          .get(`${API_BASE_URL}/api/v1/quote`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((response) => {
            setQuoteList(response.data.data);
            setData(response.data.data);
            console.log(response.data.data);
          });
      });
  };

  // function which selects all the items
  const selectorCheck = (e) => {
    let newData;
    newData = data.map((item) => {
      item.checked = e.currentTarget.checked;
      return item;
    });
    setData([...newData]);
  };

  // function to toggle the search option
  const toggle = () => setonSearch(!onSearch);

  const { errors, register, handleSubmit } = useForm();

  // Get current list, pagination
  const indexOfLastItem = currentPage * itemPerPage;
  const indexOfFirstItem = indexOfLastItem - itemPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  // Change Page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <React.Fragment>
      <Head title="User List - Compact"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h3" page>
                Quote Lists
              </BlockTitle>
              <BlockDes className="text-soft">
                <p>You have total {quoteList.length} quotes.</p>
              </BlockDes>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>

        <Block>
          <DataTable className="card-stretch">
            <div className="card-inner position-relative card-tools-toggle">
              <div className="card-title-group">
                <div className="card-tools mr-n1">
                  <ul className="btn-toolbar gx-1">
                    <li>
                      <a
                        href="#search"
                        onClick={(ev) => {
                          ev.preventDefault();
                          toggle();
                        }}
                        className="btn btn-icon search-toggle toggle-search"
                      >
                        <Icon name="search"></Icon>
                      </a>
                    </li>
                    <li className="btn-toolbar-sep"></li>
                    <button className="btn btn-primary" onClick={() => setModal({ add: true })}>
                      Add New Quote
                    </button>
                  </ul>
                </div>
              </div>
              <div className={`card-search search-wrap ${!onSearch && "active"}`}>
                <div className="card-body">
                  <div className="search-content">
                    <Button
                      className="search-back btn-icon toggle-search active"
                      onClick={() => {
                        setSearchText("");
                        toggle();
                      }}
                    >
                      <Icon name="arrow-left"></Icon>
                    </Button>
                    <input
                      type="text"
                      className="border-transparent form-focus-none form-control"
                      placeholder="Search by quote id or status"
                      value={onSearchText}
                      onChange={(e) => onFilterChange(e)}
                    />
                    <Button className="search-submit btn-icon">
                      <Icon name="search"></Icon>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <DataTableBody compact>
              <DataTableHead>
                <DataTableRow size="lg">
                  <span className="sub-text">User Email</span>
                </DataTableRow>
                <DataTableRow size="lg">
                  <span className="sub-text">Treatment Information</span>
                </DataTableRow>
                <DataTableRow size="lg">
                  <span className="sub-text">Specialty</span>
                </DataTableRow>
                <DataTableRow size="lg">
                  <span className="sub-text">Source Country</span>
                </DataTableRow>
                <DataTableRow size="lg">
                  <span className="sub-text">Destination Country</span>
                </DataTableRow>
                <DataTableRow size="lg">
                  <span className="sub-text">Status</span>
                </DataTableRow>
                <DataTableRow size="lg">
                  <span className="sub-text">Request Date</span>
                </DataTableRow>
                <DataTableRow className="nk-tb-col-tools text-right"></DataTableRow>
              </DataTableHead>
              {/*Head*/}
              {currentItems.length > 0
                ? currentItems.map((item) => {
                    return (
                      <DataTableItem key={item._id}>
                        <DataTableRow size="lg">
                          <span>{item?.user?.email}</span>
                        </DataTableRow>
                        <DataTableRow size="lg">
                          <span>{item.treatment_info}</span>
                        </DataTableRow>
                        <DataTableRow size="lg">
                          <span>{item.specialty}</span>
                        </DataTableRow> 
                        <DataTableRow size="lg">
                          <span>{item.source_country}</span>
                        </DataTableRow>
                        <DataTableRow size="lg">
                          <span>{item.destination_country}</span>
                        </DataTableRow>
                        {/* <DataTableRow size="lg">
                          <span>{item.remarks}</span>
                        </DataTableRow> */}
                        <DataTableRow size="lg">
                          <span>{item.status}</span>
                        </DataTableRow>
                        <DataTableRow size="lg">
                          <span>{moment(item.createdAt).format("DD/MM/YY")}</span>
                        </DataTableRow>
                        <DataTableRow className="nk-tb-col-tools">
                          <ul className="nk-tb-actions gx-1">
                            <li>
                              <UncontrolledDropdown>
                                <DropdownToggle tag="a" className="dropdown-toggle btn btn-icon btn-trigger">
                                  <Icon name="more-h"></Icon>
                                </DropdownToggle>
                                <DropdownMenu right>
                                  <ul className="link-list-opt no-bdr">
                                    <li onClick={() => onViewClick(item._id)}>
                                      <DropdownItem
                                        tag="a"
                                        href="#view"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                        }}
                                      >
                                        <Icon name="eye"></Icon>
                                        <span>View</span>
                                      </DropdownItem>
                                    </li>
                                    <li onClick={() => onEditClick(item._id)}>
                                      <DropdownItem
                                        tag="a"
                                        href="#edit"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                        }}
                                      >
                                        <Icon name="edit"></Icon>
                                        <span>Edit</span>
                                      </DropdownItem>
                                    </li>

                                    <li onClick={() => onDeleteClick(item._id)}>
                                      <DropdownItem
                                        tag="a"
                                        href="#delete"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                        }}
                                      >
                                        <Icon name="delete"></Icon>
                                        <span>Delete</span>
                                      </DropdownItem>
                                    </li>
                                  </ul>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </li>
                          </ul>
                        </DataTableRow>
                      </DataTableItem>
                    );
                  })
                : null}
            </DataTableBody>
            <div className="card-inner">
              {currentItems.length > 0 ? (
                <PaginationComponent
                  itemPerPage={itemPerPage}
                  totalItems={quoteList.length}
                  paginate={paginate}
                  currentPage={currentPage}
                />
              ) : (
                <div className="text-center">
                  <span className="text-silent">No data found</span>
                </div>
              )}
            </div>
          </DataTable>
        </Block>

        {/* ------------------ new quote add ------------------------ */}
        <Modal isOpen={modal.add} toggle={() => setModal({ add: false })} className="modal-dialog-centered" size="lg">
          <ModalBody>
            <a
              href="#cancel"
              onClick={(ev) => {
                ev.preventDefault();
                onFormCancel();
              }}
              className="close"
            >
              <Icon name="cross-sm"></Icon>
            </a>
            <div className="p-2">
              <h5 className="title">Add Quote</h5>
              <div className="mt-4">
                <Form className="row gy-4" onSubmit={handleSubmit(onFormSubmit)}>
                  <Col md="4">
                    <FormGroup>
                      <label className="form-label">Salutation</label>
                      <div className="form-control-wrap">
                        <RSelect
                          name="salutaton"
                          placeholder="Mr. or Mrs."
                          options={filterStatus}
                          onChange={(e) => setFormData({ ...formData, salutation: e.value })}
                        />
                      </div>
                      {errors.name && <span className="invalid">{errors.name.message}</span>}
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup>
                      <label className="form-label">First Name</label>
                      <input
                        className="form-control"
                        type="text"
                        name="first_name"
                        defaultValue={formData.user.first_name}
                        placeholder="first_name"
                        ref={register({ required: "This field is required" })}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup>
                      <label className="form-label">Last Name</label>
                      <input
                        className="form-control"
                        type="text"
                        name="last_name"
                        defaultValue={formData.user.last_name}
                        placeholder="last_name"
                        ref={register({ required: "This field is required" })}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup>
                      <label className="form-label">Email </label>
                      <input
                        className="form-control"
                        type="text"
                        name="email"
                        defaultValue={formData.user.email}
                        placeholder="Enter email"
                        ref={register({
                          required: "This field is required",
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: "invalid email address",
                          },
                        })}
                      />
                      {errors.email && <span className="invalid">{errors.email.message}</span>}
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup>
                      <label className="form-label">Country Code</label>
                      <div className="form-control-wrap">
                        <RSelect
                          name="country_code"
                          options={countryOptions}
                          onChange={(e) => setFormData({ ...formData, country_code: e.value })}
                        />
                      </div>
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup>
                      <label className="form-label">Phone</label>
                      <input
                        className="form-control"
                        type="number"
                        name="phone"
                        defaultValue={formData.user.phone}
                        ref={register({
                          required: "This field is required",
                        })}
                      />
                      {errors.phone && <span className="invalid">{errors.phone.message}</span>}
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup>
                      <label className="form-label">Treatment</label>
                      <input
                        className="form-control"
                        type="treatment"
                        name="treatment_info"
                        defaultValue={formData.treatment_info}
                        placeholder="treatment_info"
                        ref={register({ required: "This field is required" })}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup>
                      <label className="form-label">Specialty</label>
                      <input
                        className="form-control"
                        type="text"
                        name="specialty"
                        defaultValue={formData.specialty}
                        placeholder="Specialty"
                      />
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup>
                      <label className="form-label">Origin Country</label>
                      <div className="form-control-wrap">
                        <RSelect
                          name="source_country"
                          options={countryListOptions}
                          onChange={(e) => setFormData({ ...formData, source_country: e.value })}
                        />
                      </div>
                    </FormGroup>
                  </Col>

                  <Col md="4">
                    <FormGroup>
                      <label className="form-label">Destination Country</label>
                      <div className="form-control-wrap">
                        <RSelect
                          name="destination_country"
                          options={countryListOptions}
                          onChange={(e) => setFormData({ ...formData, destination_country: e.value })}
                        />
                      </div>
                    </FormGroup>
                  </Col>
                  <Col md="12">
                    <FormGroup>
                      <label className="form-label">Remarks</label>
                      <input
                        className="form-control"
                        type="text"
                        name="remarks"
                        defaultValue={formData.remarks}
                        placeholder="Remarks"
                      />
                    </FormGroup>
                  </Col>

                  <Col size="12">
                    <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                      <li>
                        <Button color="primary" size="md" type="submit">
                          Add Quote
                        </Button>
                      </li>
                      <li>
                        <a
                          href="#cancel"
                          onClick={(ev) => {
                            ev.preventDefault();
                            onFormCancel();
                          }}
                          className="link link-light"
                        >
                          Cancel
                        </a>
                      </li>
                    </ul>
                  </Col>
                </Form>
              </div>
            </div>
          </ModalBody>
        </Modal>
        {/* ------------------------- edit modal---------------------*/}
        <Modal isOpen={modal.edit} toggle={() => setModal({ edit: false })} className="modal-dialog-centered" size="lg">
          <ModalBody>
            <a
              href="#cancel"
              onClick={(ev) => {
                ev.preventDefault();
                onFormCancel();
              }}
              className="close"
            >
              <Icon name="cross-sm"></Icon>
            </a>
            <div className="p-2">
              <h5 className="title">Update Quote</h5>
              <div className="mt-4">
                <Form className="row gy-4" onSubmit={handleSubmit(onEditSubmit)}>
                  <input type="hidden" value={formData._id} name="_id" />
                  <Col md="12">
                    <FormGroup>
                      <label className="form-label">Treatment Info</label>
                      <input
                        className="form-control"
                        type="text"
                        name="treatment_info"
                        defaultValue={formData.treatment_info}
                        placeholder="Treatment Info"
                        ref={register({ required: "This field is required" })}
                      />
                      {errors.name && <span className="invalid">{errors.name.message}</span>}
                    </FormGroup>
                    <FormGroup>
                      <label className="form-label">Specialty</label>
                      <input
                        className="form-control"
                        type="text"
                        name="specialty"
                        defaultValue={formData.specialty}
                        placeholder="Specialty"
                        ref={register({ required: "This field is required" })}
                      />
                      {errors.name && <span className="invalid">{errors.name.message}</span>}
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <label className="form-label">Origin Country</label>
                      <div className="form-control-wrap">
                        <RSelect
                          name="source_country"
                          options={countryListOptions}
                          defaultValue={{ value: formData.source_country, label: formData.source_country }}
                          onChange={(e) => setFormData({ ...formData, source_country: e.value })}
                        />
                      </div>
                    </FormGroup>
                  </Col>

                  <Col md="6">
                    <FormGroup>
                      <label className="form-label">Destination Country</label>
                      <div className="form-control-wrap">
                        <RSelect
                          name="destination_country"
                          options={countryListOptions}
                          defaultValue={{ value: formData.destination_country, label: formData.destination_country }}
                          onChange={(e) => setFormData({ ...formData, destination_country: e.value })}
                        />
                      </div>
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <label className="form-label">Quotes Created By</label>
                      <input
                        className="form-control"
                        type="text"
                        disabled
                        defaultValue={formData.user.email}
                        placeholder="User email"
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <label className="form-label">Status</label>
                      <RSelect
                        options={ActionStatus}
                        defaultValue={{ value: formData.status, label: formData.status }}
                        onChange={(e) => setFormData({ ...formData, status: e.value })}
                      />
                      {errors.password && <span className="invalid">{errors.password.message}</span>}
                    </FormGroup>
                  </Col>
                  <Col md="12">
                    <FormGroup>
                      <label className="form-label">Remarks</label>
                      <input
                        className="form-control"
                        type="text"
                        name="remarks"
                        defaultValue={formData.remarks}
                        placeholder="Remark"
                        ref={register({ required: "This field is required" })}
                      />
                      {errors.password && <span className="invalid">{errors.password.message}</span>}
                    </FormGroup>
                  </Col>

                  <Col size="12">
                    <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                      <li>
                        <Button color="primary" size="md" type="submit">
                          Update Quote
                        </Button>
                      </li>
                      <li>
                        <a
                          href="#cancel"
                          onClick={(ev) => {
                            ev.preventDefault();
                            onFormCancel();
                          }}
                          className="link link-light"
                        >
                          Cancel
                        </a>
                      </li>
                    </ul>
                  </Col>
                </Form>
              </div>
            </div>
          </ModalBody>
        </Modal>

        {/* -----------------------user details view modal-------------------------- */}
        <Modal isOpen={modal.view} toggle={() => setModal({ view: false })} className="modal-dialog-centered" size="lg">
          <ModalBody>
            <a
              href="#cancel"
              onClick={(ev) => {
                ev.preventDefault();
                onFormCancel();
              }}
              className="close"
            >
              <Icon name="cross-sm"></Icon>
            </a>
            <div className="p-2">
              <h5 className="title">Quote Details</h5>
              <div className="mt-4">
                <Form className="row gy-4">
                  <input type="hidden" value={formData._id} name="_id" />
                  {/* <h6>User's Info</h6> */}
                  <Col md="4">
                    <FormGroup>
                      <label className="form-label">Salutation</label>
                      <p>{formData.user.salutation}</p>
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup>
                      <label className="form-label">First Name</label>
                      <p>{formData.user.first_name}</p>
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup>
                      <label className="form-label">Last Name</label>
                      <p>{formData.user.last_name}</p>
                    </FormGroup>
                  </Col>

                  <Col md="4">
                    <FormGroup>
                      <label className="form-label">Email</label>
                      <p>{formData.user.email}</p>
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup>
                      <label className="form-label">Country Code</label>
                      <p>{formData.user.country_code}</p>
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup>
                      <label className="form-label">Phone</label>
                      <p>{formData.user.phone}</p>
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup>
                      <label className="form-label">Treatment Info</label>
                      <p>{formData.treatment_info}</p>
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup>
                      <label className="form-label">Specialty</label>
                      <p>{formData.specialty}</p>
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup>
                      <label className="form-label">Souce Country</label>
                      <p>{formData.source_country}</p>
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup>
                      <label className="form-label">Destination Country</label>
                      <p>{formData.destination_country}</p>
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup>
                      <label className="form-label">Status</label>
                      <p>{formData.status}</p>
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup>
                      <label className="form-label">Created At</label>
                      <p>{moment(formData.createdAt).format("DD/MM/YY")}</p>
                    </FormGroup>
                  </Col>
                  <Col md="12">
                    <FormGroup>
                      <label className="form-label">Remarks</label>
                      <p>{formData.remarks}</p>
                    </FormGroup>
                  </Col>
                  <Col size="12">
                    <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                      <li>
                        <a
                          href="#cancel"
                          onClick={(ev) => {
                            ev.preventDefault();
                            onFormCancel();
                          }}
                          className="btn btn-primary"
                        >
                          Close
                        </a>
                      </li>
                    </ul>
                  </Col>
                </Form>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </Content>
    </React.Fragment>
  );
};
export default UserQuots;
