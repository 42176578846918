import React, { useState, useEffect, useRef, forwardRef } from "react";
import { useSelector, useDispatch } from 'react-redux';
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import swal from "sweetalert";

import "../../../assets/scss/core/pages/specialty-manage/common.scss";
import "../../../assets/scss/core/pages/specialty-manage/category.scss";

import {
  FormGroup,
  Form,
  Modal,
  ModalBody,
  Breadcrumb,
  BreadcrumbItem,
  Label,
  Input
} from "reactstrap";
import Switch from 'react-switch';
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Col,
  PaginationComponent,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableRow,
  DataTableItem,
  Button, RSelect
} from "../../../components/Component";
import { useForm } from "react-hook-form";

import {
  addCategory,
  updateCategory,
  deleteCategory,
  changeOrder
} from "../../../services/CategoryService";

import {
  changeCurrentCategory,
  fetchListAsync,
  fetchAvailableListAsync,
  getCurrentCategory,
  getCategoryList,
  getCategoryListFilter
} from '../../../stores/categorySlice';
import Dropzone from "react-dropzone";
import { uploadFile, deleteFile } from "../../../services/FileService";

const CategoryList = (props, goToRootCategoryRef) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(10);
  const [message, setMessage] = useState("");
  const [errMessage, setErrMessage] = useState("");
  const [currentFile, setCurrentFile] = useState(undefined);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [fileInfos, setFileInfos] = useState([]);
  const [editRowData, setEditRowData] = useState(null);
  const [editFieldName, setEditFieldName] = useState('');
  const [categoryBreadcrumbList, setCategoryBreadcrumbList] = useState([{
    _id: '',
    label: 'Root'
  }]);
  
  const currentCategory = useSelector(getCurrentCategory);
  const categoryList = useSelector(getCategoryList);
  const categoryListFilter = useSelector(getCategoryListFilter);
  const dispatch = useDispatch();
  
  const parentCategoryElement = useRef(null);
  const labelInputElement = useRef(null);
  const isActiveElement = useRef(null);

  const [formData, setFormData] = useState({
    _id: '',
    parentId: '',
    parentLabel: '',
    isAvailable: false,
    label: '',
    imageName: '',
    imageUrl: '',
    isActive: true
  });
  const [modal, setModal] = useState({
    edit: false,
    add: false,
    view: false,
  });
  
  // Get current list, pagination
  const indexOfLastItem = currentPage * itemPerPage;
  const indexOfFirstItem = indexOfLastItem - itemPerPage;
  const currentItems = categoryList.slice(indexOfFirstItem, indexOfLastItem);

  // unselects the data on mount
  useEffect(() => {
    dispatch(fetchListAsync(currentCategory._id));
    dispatch(fetchAvailableListAsync());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (editRowData && editFieldName !== '')
      onEditSubmit(formData);
  }, [formData]);

  // Change Page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const { errors, register, handleSubmit, reset } = useForm();

  const openAddNewModal = () => {
    setMessage('');
    setErrMessage('');
    setCurrentFile(undefined);
    setSelectedFiles([]);
    setEditRowData(null);
    resetForm();
    onCancelImage();
    
    setModal({ add: true });
  }

  // handles ondrop function of dropzone
  const handleDropChange = (acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      setSelectedFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    }
  };

  const onRemoveImage = () => {
    if (!selectedFiles || selectedFiles.length === 0)
      return;

    const isBlob = new URL(selectedFiles[0].preview).protocol === 'blob:';
    if (isBlob) {
      setSelectedFiles([]);
    } else {
      swal({
        title: 'Are you sure?',
        text: 'You will not be able to recover this image!',
        icon: 'warning',
        buttons: [
          'No, cancel it!',
          'Yes, I am sure!'
        ],
        dangerMode: true
      })
        .then((isConfirm) => {
          if (isConfirm) {
            deleteFile(editRowData.imageName, (event) => {
            })
              .then((response) => {
                setSelectedFiles([]);
                setCurrentFile(undefined);

                if (editRowData && editFieldName !== '')
                  setFormData({ ...editRowData, imageName: '', imageUrl: '' });
              })
              .catch(() => {
                setMessage("Could not delete the file!");
                swal("Opps!", "Could not delete the file!", "warning").then(r => {});
                setCurrentFile(undefined);
              });  
          }
        })
    }
  }

  const onInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  /* const onChangeCategory = (value) => {
    const category = categoryListFilter.find((each) => each.value === value);
    setFormData({ ...formData, parentId: value, parentLabel: category.label });
  } */

  const onSubmitForm = (form) => {
    onAddSubmit(form);
  }

  const onAddCategory = (imageName, imageUrl) => {
    addCategory({
      parentId: currentCategory._id,
      parentLabel: currentCategory.label,
      isAvailable: formData.isAvailable,
      label: formData.label,
      imageName: imageName,
      imageUrl: imageUrl,
      isActive: formData.isActive
    })
      .then(() => {
        setMessage('Category added successfully!');
        swal("Good job!", "Category added successfully!", "success").then(r => {});

        resetForm();

        dispatch(fetchListAsync(currentCategory._id));
        dispatch(fetchAvailableListAsync());
      })
      .catch(() => {
        setTimeout(() => {
          setErrMessage('Could not add category!');
          swal("Opps!", "Could not add category!", "warning").then(r => {});
        }, 2000);
      });
  }

  const onAddSubmit = () => {
    if (!selectedFiles || selectedFiles.length === 0) {
      onAddCategory('', '');
    } else {
      let currentFile = selectedFiles[0];

      setCurrentFile(currentFile);

      uploadFile(currentFile, () => {
      })
        .then((response) => {
          setSelectedFiles(undefined);

          setFormData({ ...formData, imageName: response.data.name, imageUrl: response.data.url });
          
          onAddCategory(response.data.name, response.data.url);
        })
        .catch(() => {
          setMessage("Could not upload the file!");
          swal("Opps!", "Could not upload the file!", "warning").then(r => {});
          setCurrentFile(undefined);
        });

      setSelectedFiles(undefined);
    }
  };

  // submit function to update a new item
  const onEditSubmit = (form) => {
    updateCategory({
      _id: formData._id,
      parentId: formData.parentId,
      parentLabel: formData.parentLabel,
      isAvailable: formData.isAvailable,
      label: formData.label,
      imageName: formData.imageName,
      imageUrl: formData.imageUrl,
      isActive: formData.isActive
    })
      .then(() => {
        setMessage('Category updated successfully!');

        setEditRowData(null);
        setEditFieldName('');

        dispatch(fetchListAsync(currentCategory._id));
        dispatch(fetchAvailableListAsync());
      })
      .catch(() => {
        setErrMessage('Could not update category!');
        swal("Opps!", "Could not update category!", "warning").then(r => {});
      });      
  };

  const onIsActiveChange = (isActive) => {
    setFormData({ ...formData, isActive: isActive });
  }

  const onRemoveCategory = (id) => {
    swal({
      title: 'Are you sure?',
      text: 'You will not be able to recover this category!',
      icon: 'warning',
      buttons: [
        'No, cancel it!',
        'Yes, I am sure!'
      ],
      dangerMode: true
    })
      .then((isConfirm) => {
        if (isConfirm) {
          deleteCategory(id)
            .then(() => {
              swal("Good job!", "Category deleted successfully!", "success").then(r => {});

              onFormCancel();
              
              dispatch(fetchListAsync(currentCategory._id));
              dispatch(fetchAvailableListAsync());
            })
            .catch((error) => {
              swal("Opps!", "Could not delete category!", "warning").then(r => {});
            });
        }
      })
  };

  // function to reset the form
  const resetForm = () => {
    setFormData({
      _id: '',
      parentId: '',
      parentLabel: '',
      isAvailable: false,
      label: '',
      imageName: '',
      imageUrl: '',
      isActive: true
    });

    reset({
      _id: '',
      parentId: '',
      parentLabel: '',
      isAvailable: false,
      label: '',
      imageName: '',
      imageUrl: '',
      isActive: true
    });
  };

  const onFormCancel = () => {
    resetForm();

    setModal({ edit: false, add: false });

    setMessage('');
    setErrMessage('');
  };

  const parentCategoryRenderer = (data, fieldName) => {
    if (editRowData && editRowData._id === data._id && fieldName === editFieldName) {
      setTimeout(() => {
        if (parentCategoryElement && parentCategoryElement.current)
          parentCategoryElement.current.focus();
      }, 100, parentCategoryElement);

      return (
        <div>
          <RSelect
            name="category"
            inputRef={parentCategoryElement}
            options={categoryListFilter}
            className="category"
            placeholder="Category"
            value={{ value: editRowData.parentId, label: editRowData.parentLabel }}
            onChange={(e) => onSaveParentCategory(e.value)}
            onBlur ={() => setEditRowData(null)}
          />
        </div>
      );
    }

    return (
      <div>
        <span>
          <span>{data.parent ? data.parent.label : 'Root'}</span>
        </span>
      </div>
    );
  }

  const onSaveParentCategory = (value) => {
    const category = categoryListFilter.find((each) => each.value === value);

    setFormData({ ...editRowData, parentId: category.value, parentLabel: category.label });
  }

  const labelRenderer = (data, fieldName) => {
    if (editRowData && editRowData._id === data._id && fieldName === editFieldName) {
      setTimeout(() => {
        if (labelInputElement && labelInputElement.current)
          labelInputElement.current.focus();
      }, 100, labelInputElement);

      return (
        <div>
          <input type="text"
                 ref={labelInputElement}
                 className="input-textbox"
                 defaultValue={data[fieldName]}
                 onKeyDown={(e) => onSaveLabel(e)}
                 onBlur ={() => setEditRowData(null)}
          />
        </div>
      );
    }

    return (
      <div>
        <span>
          {data[fieldName]}
        </span>
      </div>
    );
  }

  const onSaveLabel = (e) => {
    if (e.keyCode !== 13)
      return;

    setFormData({ ...editRowData, label: e.target.value });
  }

  const isActiveRenderer = (data, fieldName) => {
    if (editRowData && editRowData._id === data._id && fieldName === editFieldName) {
      return (
        <div>
          <Switch
            ref={isActiveElement}
            checked={editRowData.isActive}
            onChange={onSaveIsActive}
            onBlur ={() => setEditRowData(null)} />
        </div>
      );
    }

    return (
      <div>
        <span>
          <Switch
            disabled
            checked={data.isActive}
            onChange={onIsActiveChange} />
        </span>
      </div>
    );
  }

  const onSaveIsActive = (isActive) => {
    setFormData({ ...editRowData, isActive: isActive });
  }

  const onShowSubCategories = (catId, catLabel, shouldAddToBreadcrumb) => {
    dispatch(changeCurrentCategory({ _id: catId, label: catLabel }));
    dispatch(fetchListAsync(catId));

    const newCategoryBreadcrumbList = [];
    for (let i = 0; i < categoryBreadcrumbList.length; i++) {
      const each = categoryBreadcrumbList[i];
      newCategoryBreadcrumbList.push({ _id: each._id, label: each.label });
      if (each._id === catId)
        break;
    }

    if (shouldAddToBreadcrumb) {
      newCategoryBreadcrumbList.push({ _id: catId, label: catLabel });
    }
      
    setCategoryBreadcrumbList([...newCategoryBreadcrumbList]);
  }

  const imageRenderer = (data, fieldName) => {
    if (editRowData && editRowData._id === data._id && fieldName === editFieldName) {
      return (
        <div>
          <Dropzone onDrop={(acceptedFiles) => handleDropChange(acceptedFiles)}>
            {({ getRootProps, getInputProps }) => (
              <section>
                <div {...getRootProps()} className="dropzone upload-zone small bg-lighter my-2 dz-clickable">
                  <input {...getInputProps()} />
                  {selectedFiles && selectedFiles.length > 0 && selectedFiles[0].name ? (
                    <div className="dropzone-image-edit-flex-container">
                      <div
                        key={selectedFiles[0].name}
                        className="dz-preview dz-processing dz-image-preview dz-error dz-complete"
                      >
                        <div className="dz-image">
                          <img src={selectedFiles[0].preview} alt="preview" />
                        </div>
                      </div>
                      <div className="image-edit-icons-container">
                        <Icon
                          name="trash"
                          onClick={(ev) => {
                            ev.preventDefault();
                            ev.stopPropagation();
                            onRemoveImage();
                          }}
                        >
                        </Icon>
                        <Icon
                          name="save"
                          onClick={(ev) => {
                            ev.preventDefault();
                            ev.stopPropagation();
                            onSaveImage();
                          }}
                        >
                        </Icon>
                        <Icon
                          name="cross"
                          onClick={(ev) => {
                            ev.preventDefault();
                            ev.stopPropagation();
                            onCancelImage();
                          }}
                        >
                        </Icon>
                      </div>
                    </div>
                  ) : (
                    <div className="dropzone-no-image-edit-prompt-container">
                      "Drag and drop file here, or click to select file"
                      <div className="image-edit-icons-container">
                        <Icon
                          name="cross"
                          onClick={(ev) => {
                            ev.preventDefault();
                            ev.stopPropagation();
                            onCancelImage();
                          }}
                        >
                        </Icon>
                      </div>
                    </div>
                  )}
                </div>
              </section>
            )}
          </Dropzone>
        </div>
      );
    }

    return (
      <div>
        <img src={data.imageUrl} />
      </div>
    );
  }

  const onSaveImage = () => {
    if (!selectedFiles || selectedFiles.length === 0)
      return;

    const isBlob = new URL(selectedFiles[0].preview).protocol === 'blob:';
    if (!isBlob) {
      const curFile = selectedFiles[0];
      setFormData({ ...editRowData, imageName: curFile.imageName, imageUrl: curFile.imageUrl });

      return;
    }

    let currentFile = selectedFiles[0];

    setCurrentFile(currentFile);

    uploadFile(currentFile, (event) => {
    })
      .then((response) => {
        setSelectedFiles(undefined);

        setFormData({ ...editRowData, imageName: response.data.name, imageUrl: response.data.url });
      })
      .catch(() => {
        setMessage("Could not upload the file!");
        swal("Opps!", "Could not upload the file!", "warning").then(r => {});
        setCurrentFile(undefined);
      });

    setSelectedFiles(undefined);
  }

  const onCancelImage = () => {
    setEditFieldName('');
    setEditRowData(null);

    // dispatch(fetchListAsync(currentCategory._id));
  }

  const onSelectRow = (data) => {
    setEditRowData({
      _id: data._id,
      parentId: data.parent ? data.parent._id : '',
      parentLabel: data.parent ? data.parent.label : '',
      isAvailable: data.isAvailable,
      label: data.label,
      imageName: data.imageName,
      imageUrl: data.imageUrl,
      isActive: data.isActive
    });

    reset({
      _id: data._id,
      parentId: data.parent ? data.parent._id : '',
      parentLabel: data.parent ? data.parent.label : '',
      isAvailable: data.isAvailable,
      label: data.label,
      imageName: data.imageName,
      imageUrl: data.imageUrl,
      isActive: data.isActive
    });

    const curFile = {
      name: data.imageName,
      preview: data.imageUrl,
    }
    setSelectedFiles([]);
    setSelectedFiles(current => [...current, curFile]);
  }

  const onEditField = (data, fieldName) => {
    setEditFieldName(fieldName);
    onSelectRow(data);
  }

  const onDecOrder = (id) => {
    const { order: currentOrder } = categoryList.find((each) => each._id === id);
    
    if (!currentOrder || currentOrder === 1)
      return;

    onChangeOrder(id, currentOrder, -1);
  }

  const onIncOrder = (id) => {
    const { order: currentOrder } = categoryList.find((each) => each._id === id);
    
    if (!currentOrder)
      return;

    onChangeOrder(id, currentOrder, 1);
  }

  const onChangeOrder = (id, order, direction) => {
    changeOrder({
      id,
      order,
      direction,
      parent: currentCategory._id
    })
      .then(() => {
        dispatch(fetchListAsync(currentCategory._id));
      })
      .catch(() => {
        setTimeout(() => {
          setErrMessage('Could not change order!');
          swal("Opps!", "Could not change order!", "warning").then(r => {});
        }, 2000);
      });    
  }

  const handleChangeAvailable = (data, isAvailable) => {
    onEditField(data, 'isAvailable');

    setFormData({
      _id: data._id,
      parentId: data.parent ? data.parent._id : '',
      parentLabel: data.parent ? data.parent.label : '',
      isAvailable: isAvailable,
      label: data.label,
      imageName: data.imageName,
      imageUrl: data.imageUrl,
      isActive: data.isActive
    });
  }

  return (
    <React.Fragment>
      <Head title="Category List"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h3" page>
                List of Category
              </BlockTitle>
              <BlockDes className="text-soft">
                <p>You have total {categoryList.length} categories.</p>
              </BlockDes>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>

        <Block className="category-block">
          <div className="container-fluid">
            <div className="row">
              <div className="col col-12 col-md-12 col-lg-12">
                <DataTable className="card-stretch">
                  <div className="card-inner position-relative card-tools-toggle">
                    <div className="card-title-group">
                      <div>
                        <Breadcrumb>
                        {categoryBreadcrumbList.length > 0
                          ? categoryBreadcrumbList.map((item) => {
                          return (
                            <BreadcrumbItem key={item._id}><a href="#" onClick={() => onShowSubCategories(item._id, item.label, false)}>{item.label}</a></BreadcrumbItem>
                          )}) : null}
                        </Breadcrumb>
                        <input type="hidden" ref={goToRootCategoryRef} onClick={() => onShowSubCategories('', '', false)} />
                      </div>
                      <div className="card-tools mr-n1">
                        <ul className="btn-toolbar gx-1">
                          <button className="btn btn-primary" onClick={() => openAddNewModal()}>
                            Add New Category
                          </button>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <DataTableBody compact>
                    <DataTableHead>
                      {/* <DataTableRow className="nk-tb-col-check">
                        <div className="custom-control custom-control-sm custom-checkbox notext">
                          <input
                            type="checkbox"
                            className="custom-control-input form-control"
                            onChange={(e) => selectorCheck(e)}
                            id="uid"
                          />
                          <label className="custom-control-label" htmlFor="uid"></label>
                        </div>
                      </DataTableRow> */}

                      <DataTableRow size="lg">
                        <span className="sub-text">Category ID</span>
                      </DataTableRow>
                      <DataTableRow size="lg">
                        <span className="sub-text">Parent Category</span>
                      </DataTableRow>
                      <DataTableRow size="lg">
                        <span className="sub-text"></span>
                      </DataTableRow>
                      <DataTableRow size="lg">
                        <span className="sub-text">ID</span>
                      </DataTableRow>
                      <DataTableRow size="lg">
                        <span className="sub-text">Name</span>
                      </DataTableRow>
                      <DataTableRow size="lg">
                        <span className="sub-text">Image</span>
                      </DataTableRow>
                      <DataTableRow size="lg">
                        <span className="sub-text">Active</span>
                      </DataTableRow>
                      <DataTableRow size="lg">
                        <span className="sub-text">Change Order</span>
                      </DataTableRow>
                      <DataTableRow size="lg">
                        <span className="sub-text"></span>
                      </DataTableRow>
                      <DataTableRow size="lg">
                        <span className="sub-text"></span>
                      </DataTableRow>
                    </DataTableHead>
                    {/*Head*/}
                    {currentItems.length > 0
                      ? currentItems.map((item) => {
                          return (
                            <DataTableItem key={item._id}>
                              <DataTableRow size="lg">
                                <span>{item._id}</span>
                              </DataTableRow>
                              {/*<DataTableRow
                                size="lg"
                                renderer={parentCategoryRenderer(item, 'parentCategory')}
                                onClick={(ev) => {
                                  ev.preventDefault();
                                  ev.stopPropagation();
                                  onEditField(item, 'parentCategory');
                                }}
                              />*/}
                              <DataTableRow size="lg">
                                <span>{item.parentLabel ? item.parentLabel : 'Root'}</span>
                              </DataTableRow>
                              <DataTableRow size="lg">
                                <Input
                                  type="checkbox"
                                  checked={item.isAvailable}
                                  onChange={(e) => handleChangeAvailable(item, e.target.checked)}
                                />
                              </DataTableRow>
                              <DataTableRow size="lg">
                                <span>{item.uid}</span>
                              </DataTableRow>
                              <DataTableRow
                                size="lg"
                                renderer={labelRenderer(item, 'label')}
                                onClick={(ev) => {
                                  ev.preventDefault();
                                  ev.stopPropagation();
                                  onEditField(item, 'label');
                                }}
                              />
                              <DataTableRow
                                size="lg"
                                renderer={imageRenderer(item, 'imageName')}
                                onClick={(ev) => {
                                  ev.preventDefault();
                                  ev.stopPropagation();
                                  onEditField(item, 'imageName');
                                }}
                              />
                              <DataTableRow
                                size="lg"
                                renderer={isActiveRenderer(item, 'isActive')}
                                onClick={(ev) => {
                                  ev.preventDefault();
                                  ev.stopPropagation();
                                  onEditField(item, 'isActive');
                                }}
                              />
                              <DataTableRow size="lg">
                                <div className="change-order-icons-container">
                                  <Icon
                                    name="arrow-up"
                                    onClick={(ev) => {
                                      ev.preventDefault();
                                      ev.stopPropagation();
                                      onDecOrder(item._id);
                                    }}
                                  >
                                  </Icon>
                                  <Icon
                                    name="arrow-down"
                                    onClick={(ev) => {
                                      ev.preventDefault();
                                      ev.stopPropagation();
                                      onIncOrder(item._id);
                                    }}
                                  >
                                  </Icon>
                                </div>
                              </DataTableRow>
                              <DataTableRow size="lg">
                                <Icon
                                  name="trash"
                                  onClick={(ev) => {
                                    ev.preventDefault();
                                    ev.stopPropagation();
                                    onRemoveCategory(item._id);
                                  }}
                                >
                                </Icon>
                              </DataTableRow>
                              <DataTableRow size="lg">
                                <div className="view-sub-categories-container">
                                  <Icon
                                    name="arrow-right"
                                    onClick={(ev) => {
                                      ev.preventDefault();
                                      ev.stopPropagation();
                                      onShowSubCategories(item._id, item.label, true);
                                    }}
                                  >
                                  </Icon>
                                </div>
                              </DataTableRow>
                            </DataTableItem>
                          );
                        })
                      : null}
                  </DataTableBody>
                  <div className="card-inner">
                    {currentItems.length > 0 ? (
                      <PaginationComponent
                        itemPerPage={itemPerPage}
                        totalItems={categoryList.length}
                        paginate={paginate}
                        currentPage={currentPage}
                      />
                    ) : (
                      <div className="text-center">
                        <span className="text-silent">No data found</span>
                      </div>
                    )}
                  </div>
                </DataTable>
              </div>
            </div>
          </div>
        </Block>

        <Modal isOpen={modal.add} toggle={() => setModal({ add: false })} className="modal-dialog-centered" size="lg">
          <ModalBody>
            <a
              href="#cancel"
              onClick={(ev) => {
                ev.preventDefault();
                onFormCancel();
              }}
              className="close"
            >
              <Icon name="cross-sm"></Icon>
            </a>
            <div className="p-2">
              <h5 className="title">Add New Category</h5>
              <div className="mt-4">
                <Form className="row pt-2 gy-4" onSubmit={handleSubmit(onSubmitForm)}>
                  <input type="hidden" value={formData._id} name="_id" />
                  {/* <Col md="12">
                    <FormGroup>
                      <label className="form-label">Category</label>
                      <RSelect
                        name="category"
                        options={categoryListFilter}
                        className="category"
                        placeholder="Category"
                        value={{ value: formData.parentId, label: formData.parentLabel }}
                        onChange={(e) => onChangeCategory(e.value)}
                      />
                      {errors.category && <span className="invalid">{errors.category.message}</span>}
                    </FormGroup>
                  </Col> */}
                  <Col md="12">
                    <FormGroup>
                      <label className="form-label">Parent Category: {currentCategory && currentCategory.label}</label>
                    </FormGroup>
                  </Col>
                  <Col md="12">
                    <FormGroup>
                      <label className="form-label">Name</label>
                      <input
                        className="form-control"
                        type="text"
                        name="label"
                        defaultValue={formData.label}
                        placeholder="Name"
                        ref={register({ required: "This field is required" })}
                        onChange={(e) => onInputChange(e)}
                      />
                      {errors.label && <span className="invalid">{errors.label.message}</span>}
                    </FormGroup>
                  </Col>
                  <Col md="12">
                    <FormGroup>
                      <label className="form-label">Image</label>
                      <Dropzone onDrop={(acceptedFiles) => handleDropChange(acceptedFiles)}>
                        {({ getRootProps, getInputProps }) => (
                          <section>
                            <div {...getRootProps()} className="dropzone upload-zone small bg-lighter my-2 dz-clickable">
                              <input {...getInputProps()} />
                              {selectedFiles && selectedFiles.length > 0 && selectedFiles[0].name ? (
                                <div>
                                  <div
                                    key={selectedFiles[0].name}
                                    className="dz-preview dz-processing dz-image-preview dz-error dz-complete"
                                  >
                                    <div className="dz-image">
                                      <img src={selectedFiles[0].preview} alt="preview" />
                                    </div>
                                  </div>
                                  <Icon
                                    name="trash"
                                    onClick={(ev) => {
                                      ev.preventDefault();
                                      ev.stopPropagation();
                                      onRemoveImage();
                                    }}
                                  >
                                  </Icon>
                                </div>
                              ) : (
                                "Drag and drop file here, or click to select file"
                              )}
                            </div>
                          </section>
                        )}
                      </Dropzone>
                      {fileInfos.length > 0 && (
                        <div className="card">
                          <ul className="list-group list-group-flush">
                            {fileInfos.map((file, index) => (
                              <li className="list-group-item" key={index}>
                                <a href={file.url}>{file.name}</a>
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </FormGroup>
                  </Col>
                  <Col md="12">
                    <FormGroup className="is-active-form-group">
                      <label className="form-label is-active-label">Is Active</label>
                      <Switch onChange={onIsActiveChange} checked={formData.isActive} />
                    </FormGroup>
                  </Col>
                  <Col size="12">
                    {message && message !== '' && (
                      <div className="alert alert-light" role="alert">
                        {message}
                      </div>
                    )}
                    {errMessage && errMessage !== '' && (
                      <div className="alert alert-error" role="alert">
                        {errMessage}
                      </div>
                    )}
                  </Col>
                  <Col size="12">
                    <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                      <li>
                        <Button color="primary" size="md" type="submit">
                          {formData._id ? 'Edit' : 'Add'} Category
                        </Button>
                      </li>
                      <li>
                        <a
                          href="#cancel"
                          onClick={(ev) => {
                            ev.preventDefault();
                            onFormCancel();
                          }}
                          className="link link-light"
                        >
                          Cancel
                        </a>
                      </li>
                    </ul>
                  </Col>
                </Form>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </Content>
    </React.Fragment>
  );
};
export default forwardRef(CategoryList);
