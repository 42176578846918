import React, { useState, useEffect, useContext } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import { findUpper } from "../../../utils/Utils";
import { userData, filterRole, filterStatus } from "./UserData";
import moment from "moment";

import {
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  UncontrolledDropdown,
  Modal,
  ModalBody,
  DropdownItem,
  Form,
} from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Row,
  Col,
  UserAvatar,
  PaginationComponent,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableRow,
  DataTableItem,
  Button,
  RSelect,
  TooltipComponent,
} from "../../../components/Component";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { UserContext } from "./UserContext";
import { bulkActionOptions } from "../../../utils/Utils";
import axios from "axios";
import { API_BASE_URL } from "../../../utils/Utils";

const ContactForm = () => {
  const { contextData } = useContext(UserContext);
  const [data, setData] = contextData;

  const [sm, updateSm] = useState(false);
  const [onSearch, setonSearch] = useState(true);
  const [modal, setModal] = useState({
    edit: false,
    add: false,
  });
  const [editId, setEditedId] = useState();
  const [formData, setFormData] = useState({
    _id: "",
    name: "",
    email: "",
    subject: "",
    message: "",
    createdAt: "",
  });

  const [actionText, setActionText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [contactFormList, setContactFormList] = useState([]);
  const [token, setToken] = useState(localStorage.getItem("accessToken"));

  // Get user data

  useEffect(() => {
    console.log("useeffect");
    axios.get(`${API_BASE_URL}/api/v1/contact-form`).then((response) => {
      setContactFormList(response.data.data);
      setData(response.data.data);
      console.log("response", response);
      console.log(response.data.data.length);
    });
    // Your code here
  }, []);

  // function to reset the form
  const resetForm = () => {
    setFormData({
      _id: "",
      name: "",
      email: "",
      subject: "",
      message: "",
      createdAt: "",
    });
  };

  // function to close the form modal
  const onFormCancel = () => {
    setModal({ edit: false, add: false });
    resetForm();
  };

  // function that loads the want to editted data
  const onEditClick = (id) => {
    contactFormList.forEach((item) => {
      if (item._id === id) {
        setFormData({
          _id: item._id,
          name: item.name,
          email: item.email,
          subject: item.subject,
          message: item.message,
          createdAt: item.createdAt,
        });
        setModal({ edit: true }, { add: false });
        setEditedId(item._id);
      }
    });
  };

  // Get current list, pagination
  const indexOfLastItem = currentPage * itemPerPage;
  const indexOfFirstItem = indexOfLastItem - itemPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  // Change Page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <React.Fragment>
      <Head title="Contact Forms"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h3" page>
                Contact Forms
              </BlockTitle>
              <BlockDes className="text-soft">
                <p>You have total {contactFormList.length} contact request.</p>
              </BlockDes>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>

        <Block>
          <DataTable className="card-stretch">
            <div className="card-inner position-relative card-tools-toggle"></div>
            <DataTableBody compact>
              <DataTableHead>
                <DataTableRow size="lg">
                  <span className="sub-text"> Name</span>
                </DataTableRow>
                <DataTableRow size="lg">
                  <span className="sub-text">Email</span>
                </DataTableRow>
                <DataTableRow size="lg">
                  <span className="sub-text">Subject</span>
                </DataTableRow>
                <DataTableRow size="lg">
                  <span className="sub-text">Message</span>
                </DataTableRow>
                <DataTableRow size="lg">
                  <span className="sub-text">Time</span>
                </DataTableRow>
                {/* <DataTableRow size="lg">
                  <span className="sub-text">Actions</span>
                </DataTableRow> */}

                <DataTableRow className="nk-tb-col-tools text-right"></DataTableRow>
              </DataTableHead>
              {/*Head*/}
              {currentItems.length > 0
                ? currentItems.map((item) => {
                    return (
                      <DataTableItem key={item.id}>
                        <DataTableRow size="lg">
                          <span>{item.name}</span>
                        </DataTableRow>
                        <DataTableRow size="lg">
                          <span>{item.email}</span>
                        </DataTableRow>
                        <DataTableRow size="lg">
                          <span>{item.subject}</span>
                        </DataTableRow>
                        <DataTableRow size="lg">
                          <span>{item.message}</span>
                        </DataTableRow>

                        <DataTableRow size="md">
                          <span>{moment(item.createdAt).format("DD/MM/YY")}</span>
                        </DataTableRow>

                        <DataTableRow className="nk-tb-col-tools">
                          <ul className="nk-tb-actions gx-1">
                            {/* <li className="nk-tb-action-hidden" onClick={() => onEditClick(item._id)}>
                              <TooltipComponent
                                tag="a"
                                containerClassName="btn btn-trigger btn-icon"
                                id={"edit" + item.id}
                                icon="edit-alt-fill"
                                direction="top"
                                text="Edit"
                              />
                            </li> */}

                            <li>
                              <UncontrolledDropdown>
                                <DropdownToggle tag="a" className="dropdown-toggle btn btn-icon btn-trigger">
                                  <Icon name="more-h"></Icon>
                                </DropdownToggle>
                                <DropdownMenu right>
                                  <ul className="link-list-opt no-bdr">
                                    <li onClick={() => onEditClick(item._id)}>
                                      <DropdownItem
                                        tag="a"
                                        href="#edit"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                        }}
                                      >
                                        <Icon name="view"></Icon>
                                        <span>View</span>
                                      </DropdownItem>
                                    </li>
                                  </ul>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </li>
                          </ul>
                        </DataTableRow>
                      </DataTableItem>
                    );
                  })
                : null}
            </DataTableBody>
            <div className="card-inner">
              {currentItems.length > 0 ? (
                <PaginationComponent
                  itemPerPage={itemPerPage}
                  totalItems={contactFormList.length}
                  paginate={paginate}
                  currentPage={currentPage}
                />
              ) : (
                <div className="text-center">
                  <span className="text-silent">No data found</span>
                </div>
              )}
            </div>
          </DataTable>
        </Block>

        <Modal isOpen={modal.edit} toggle={() => setModal({ edit: false })} className="modal-dialog-centered" size="lg">
          <ModalBody>
            <a
              href="#cancel"
              onClick={(ev) => {
                ev.preventDefault();
                onFormCancel();
              }}
              className="close"
            >
              <Icon name="cross-sm"></Icon>
            </a>
            <div className="p-2">
              <h5 className="title">View Contact Form</h5>
              <div className="mt-4">
                <Form className="row gy-4">
                  <input type="hidden" value={formData._id} name="_id" />
                  <Col md="12">
                    <FormGroup>
                      <label className="form-label">Name</label>
                      <p>{formData.name}</p>
                      {/* <input
                        className="form-control"
                        type="text"
                        name="treatment_info"
                        defaultValue={formData.treatment_info}
                        placeholder="Treatment Info"
                        ref={register({ required: "This field is required" })}
                      /> */}
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <label className="form-label">Email</label>
                      <p>{formData.email}</p>
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <label className="form-label">Subject</label>
                      <p>{formData.subject}</p>
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <label className="form-label">Message</label>
                      <p>{formData.message}</p>
                    </FormGroup>
                  </Col>

                  <Col size="12">
                    <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                      <li>
                        <a href={`mailto:${formData.email}`} target="_blank" className="btn btn-primary">
                          Reply
                        </a>
                      </li>
                      <li>
                        <a
                          href="#cancel"
                          onClick={(ev) => {
                            ev.preventDefault();
                            onFormCancel();
                          }}
                          className="link link-light"
                        >
                          Cancel
                        </a>
                      </li>
                    </ul>
                  </Col>
                </Form>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </Content>
    </React.Fragment>
  );
};
export default ContactForm;
