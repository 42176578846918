import http from "../utils/http-common";

const token = localStorage.getItem("accessToken");

export const getProductList = (searchInfo) => {
  return http
    .post('api/v1/product/list', searchInfo, {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${token}`
      },
    });
}

export const getSingleProduct = (id) => {
  return http
    .get(`api/v1/product/${id}`, {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${token}`
      },
    });
}

export const addProduct = (productInfo) => {
  return http.post('/api/v1/product', productInfo, {
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${token}`
    },
  });
}

export const updateProduct = (productInfo) => {
  return http.put(`/api/v1/product/${productInfo._id}`, productInfo, {
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${token}`
    },
  });
}

export const deleteProduct = (id) => {
  return http.delete(`/api/v1/product/${id}`, {
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${token}`
    },
  });
}

export const changeOrder = (changeInfo) => {
  return http
    .post('api/v1/product/change_order', changeInfo, {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${token}`
      },
    });
}
