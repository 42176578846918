import React, { useState, useRef } from "react";
import classnames from 'classnames';
import Content from "../../../layout/content/Content";

import CategoryList from "./CategoryList";
import ProductList from './ProductList';

import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col
} from "reactstrap";
import {
  Block
} from "../../../components/Component";

const ParentSpecialtyLayout = () => {
  const categoryListElement = useRef(null);

  const [activeTab, setActiveTab] = useState('category');

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }

    if (tab === 'category') {
      categoryListElement.current.click();
    }
  }

  return (
    <React.Fragment>
      <Content>
        <Block>
          <Nav tabs>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === 'category' })}
                onClick={() => { toggle('category'); }}
              >
                Category
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === 'product' })}
                onClick={() => { toggle('product'); }}
              >
                Product
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="category">
              <Row>
                <Col sm="12">
                  <CategoryList ref={categoryListElement} />
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="product">
              <Row>
                <Col sm="12">
                  <ProductList />
                </Col>
              </Row>
            </TabPane>
          </TabContent>
        </Block>
      </Content>
    </React.Fragment>
  );
};
export default ParentSpecialtyLayout;
