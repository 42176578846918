import React, { useState, useEffect, useRef, useMemo } from "react";
import { useSelector, useDispatch } from 'react-redux';
import swal from "sweetalert";
// import DropdownTreeSelect from "react-dropdown-tree-select";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import TreeDropdown from '../../../components/dropdown/TreeDropdown';

import 'react-dropdown-tree-select/dist/styles.css'
import "../../../assets/scss/core/pages/specialty-manage/product.scss";

import {
  FormGroup,
  Form,
  Modal,
  ModalBody
} from "reactstrap";
import Switch from 'react-switch';
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Col,
  PaginationComponent,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableRow,
  DataTableItem,
  Button,
  RSelect
} from "../../../components/Component";
import { useForm } from "react-hook-form";
import {
  getProductList,
  addProduct,
  updateProduct,
  deleteProduct,
  changeOrder
} from "../../../services/ProductService";
import {
  getCategoryList,
  getCategoryListFilter,
  getAvailableCategoryList
} from '../../../stores/categorySlice';

let checkedCategories = [];

const ProductList = (props) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(10);
  const [productList, setProductList] = useState([]);
  
  const [message, setMessage] = useState("");
  const [errMessage, setErrMessage] = useState("");
  const [formData, setFormData] = useState({
    _id: '',
    categories: [],
    qty: 1,
    price: 0,
    discount: 0,
    totalPrice: 0,
    order: 1,
    isActive: true
  });
  const [availableCategories, setAvailableCategories] = useState([]);

  const [modal, setModal] = useState({
    edit: false,
    add: false,
    view: false,
  });
  const [editRowData, setEditRowData] = useState(null);
  const [editFieldName, setEditFieldName] = useState('');

  const categoryList = useSelector(getCategoryList);
  const categoryListFilter = useSelector(getCategoryListFilter);
  const availableCategoryList = useSelector(getAvailableCategoryList);
  
  const categoryElement = useRef(null);
  const qtyElement = useRef(null);
  const priceElement = useRef(null);
  const discountElement = useRef(null);
  const isActiveElement = useRef(null);

  // Get current list, pagination
  const indexOfLastItem = currentPage * itemPerPage;
  const indexOfFirstItem = indexOfLastItem - itemPerPage;
  const currentItems = productList.slice(indexOfFirstItem, indexOfLastItem);

  // unselects the data on mount
  useEffect(() => {
    getProducts();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!modal.add) return;

    setFormData({ ...formData, totalPrice: formData.qty * formData.price - formData.discount });
    
    reset({
      _id: formData._id,
      qty: formData.qty,
      price: formData.price,
      discount: formData.discount,
      totalPrice: formData.qty * formData.price - formData.discount,
      order: formData.order,
      isActive: formData.isActive
    });
  }, [modal, formData.qty, formData.price, formData.discount]);

  useEffect(() => {
    if (editRowData && editFieldName !== '') {
      onEditSubmit(formData);
    }
  }, [formData]);

  // Change Page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const { errors, register, handleSubmit, reset } = useForm();

  const getProducts = () => {
    getProductList()
      .then((response) => {        
        setProductList([...response.data.data]);
      })
      .catch(() => {
        setTimeout(() => {
          swal("Opps!", "Could not get product list", "warning");
        }, 2000);
      });
  }

  const openAddNewModal = () => {
    setMessage('');
    setErrMessage('');
    setEditRowData(null);
    resetForm();

    setAvailableCategories([...availableCategoryList]);

    let categories = availableCategoryList.map((each) => ({
      _id: each._id,
      name: each.label,
      children: {
        _id: '',
        label: ''
      }
    }));
    setFormData({ ...formData, categories: categories });
    
    setModal({ add: true });
  }

  const onSelectRow = (data) => {
    setEditRowData({
      _id: data._id,
      categories: data.categories,
      qty: data.qty,
      price: data.price,
      discount: data.discount,
      isActive: data.isActive
    });

    reset({
      _id: data._id,
      categories: data.categories,
      qty: data.qty,
      price: data.price,
      discount: data.discount,
      isActive: data.isActive
    });
  }

  const onEditField = (data, fieldName) => {
    setEditFieldName(fieldName);
    onSelectRow(data);

    setAvailableCategories([...availableCategoryList]);
  }

  const onSubmitForm = (form) => {
    onAddSubmit(form);
  }

  const onAddSubmit = (form) => {
    if (formData.qty < 1) {
      swal("Opps!", "Qty must be greater than or equal to 1.", "warning");
      return;
    }

    if (formData.price < 0) {
      swal("Opps!", "Price must be greater than or equal to 0.", "warning");
      return;
    }

    if (formData.discount < 0) {
      swal("Opps!", "Discount must be greater than or equal to 0.", "warning");
      return;
    }

    if (formData.totalPrice < 0) {
      swal("Opps!", "Total price must be greater than or equal to 0.", "warning");
      return;
    }    
    
    addProduct({
      categories: formData.categories,
      qty: formData.qty,
      price: formData.price,
      discount: formData.discount,
      totalPrice: formData.totalPrice,
      order: formData.order,
      isActive: formData.isActive
    })
      .then((response) => {
        setMessage('Product added successfully!');
        swal("Good job!", "Product added successfully!", "success");

        resetForm();

        getProducts();
      })
      .catch((error) => {
        setTimeout(() => {
          setErrMessage('Could not add product!');
          swal("Opps!", "Could not add product!", "warning");
        }, 2000);
      });
  };

  // submit function to update a new item
  const onEditSubmit = (form) => {
    updateProduct({
      _id: formData._id,
      categories: formData.categories,
      qty: formData.qty,
      price: formData.price,
      discount: formData.discount,
      totalPrice: formData.totalPrice,
      order: formData.order,
      isActive: formData.isActive
    })
      .then(() => {
        setMessage('Product updated successfully!');

        setEditRowData(null);
        setEditFieldName('');

        getProducts();
      })
      .catch((error) => {
        setErrMessage('Could not update product!');
        swal("Opps!", "Could not update product!", "warning");
      });      
  };

  const onIsActiveChange = (isActive) => {
    setFormData({ ...formData, isActive: isActive });
  }

  const onRemoveProduct = (id) => {
    swal({
      title: 'Are you sure?',
      text: 'You will not be able to recover this product!',
      icon: 'warning',
      buttons: [
        'No, cancel it!',
        'Yes, I am sure!'
      ],
      dangerMode: true
    })
      .then((isConfirm) => {
        if (isConfirm) {
          deleteProduct(id)
            .then((response) => {
              swal("Good job!", "Product deleted successfully!", "success");

              onFormCancel();
              getProducts();
            })
            .catch((error) => {
              swal("Opps!", "Could not delete product!", "warning");
            });
        }
      })
  };

  // function to reset the form
  const resetForm = () => {
    setFormData({
      _id: '',
      categories: [],
      qty: 1,
      price: 0,
      discount: 0,
      totalPrice: 0,
      order: 1,
      isActive: true
    });

    reset({
      _id: '',
      categories: [],
      qty: 1,
      price: 0,
      discount: 0,
      totalPrice: 0,
      order: 1,
      isActive: true
    });
  };

  // function to close the form modal
  const onFormCancel = () => {
    resetForm();

    setModal({ edit: false, add: false });

    setMessage('');
    setErrMessage('');
  };

  const onChangeCategory = (currentNode, selectedNodes) => {
    let rootCatId = currentNode.path;
    const currentPathArray = currentNode.path.toString().split('#');
    if (currentPathArray.length > 0)
      rootCatId = currentPathArray[0];    

    const children = selectedNodes.map((each) => {
      const pathArray = each.path.toString().split('#');
      if (pathArray.length === 0)
        return { _id: each.path.toString() };
      else
        return { _id: pathArray[pathArray.length - 1].toString() };
    });

    formData.categories.map((each) => {
      if (each._id === rootCatId) {
        return Object.assign(each, { children })
      }
    });
  }

  const onInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const assignObjectPaths = (obj, stack) => {
    Object.keys(obj).forEach(k => {
      let node = obj[k];
      if (typeof node === "object") {
        if (checkedCategories.includes(node._id))
          node.checked = true;

        assignObjectPaths(node, node.path);
      }
    });
  };

  const categoryRenderer = (availableCategoryData, data) => {
    let currentCategory = null;
    currentCategory = data.categories.find((each) => {
      if (each && availableCategoryData && each._id === availableCategoryData._id)
        return each;
    });

    if (editRowData && editRowData._id === data._id && currentCategory && currentCategory.label === editFieldName) {
      setTimeout(() => {
        if (categoryElement && categoryElement.current)
          categoryElement.current.focus();
      }, 100, categoryElement);

      checkedCategories = currentCategory.children.map((each) => each._id);

      var newCategories = JSON.parse(JSON.stringify(availableCategoryData.children));
      assignObjectPaths(newCategories);

      return (
        <div>
          <TreeDropdown
            inputRef={categoryElement}
            data={newCategories}
            onChange={onSaveCategory}
            onBlur ={() => setEditRowData(null)}
          />
        </div>
      );
    }

    const categoryText = currentCategory && currentCategory.children ? currentCategory.children.map((item) => item.label).join(', ') : '';
    
    return (
      <div>
        <span>
          <span>{categoryText}</span>
        </span>
      </div>
    );
  }

  const onSaveCategory = (currentNode, selectedNodes) => {
    let rootCatId = currentNode.path;
    const currentPathArray = currentNode.path.toString().split('#');
    if (currentPathArray.length > 0)
      rootCatId = currentPathArray[0];    

    const children = selectedNodes.map((each) => {
      const pathArray = each.path.toString().split('#');
      if (pathArray.length === 0)
        return { _id: each.path.toString() };
      else
        return { _id: pathArray[pathArray.length - 1].toString() };
    });

    let newCategories = [];
    if (editRowData.categories) {
      for (let i = 0; i < editRowData.categories.length; i++) {
        if (editRowData.categories[i].label === editFieldName) {
          newCategories.push({
            _id: editRowData.categories[i]._id,
            label: editRowData.categories[i].label,
            children: children
          });
        } else {
          newCategories.push(editRowData.categories[i]);
        }
      }      
    }

    setFormData({ ...editRowData, categories: newCategories });
  }

  const qtyRenderer = (data, fieldName) => {
    if (editRowData && editRowData._id === data._id && fieldName === editFieldName) {
      setTimeout(() => {
        if (qtyElement && qtyElement.current)
          qtyElement.current.focus();
      }, 100, qtyElement);

      return (
        <div>
          <input type="text"
                 ref={qtyElement}
                 className="input-textbox"
                 defaultValue={data[fieldName]}
                 onKeyDown={(e) => onSaveQty(e)}
                 onBlur ={() => setEditRowData(null)}
          />
        </div>
      );
    }

    return (
      <div>
        <span>
          {data[fieldName]}
        </span>
      </div>
    );
  }

  const onSaveQty = (e) => {
    if (e.keyCode !== 13)
      return;

    if (e.target.value < 1) {
      swal("Opps!", "Qty must be greater than or equal to 1.", "warning");
      return;
    }

    setFormData({ ...editRowData, qty: e.target.value });
  }

  const priceRenderer = (data, fieldName) => {
    if (editRowData && editRowData._id === data._id && fieldName === editFieldName) {
      setTimeout(() => {
        if (priceElement && priceElement.current)
          priceElement.current.focus();
      }, 100, priceElement);

      return (
        <div>
          <input type="text"
                 ref={priceElement}
                 className="input-textbox"
                 defaultValue={data[fieldName]}
                 onKeyDown={(e) => onSavePrice(e)}
                 onBlur ={() => setEditRowData(null)}
          />
        </div>
      );
    }

    return (
      <div>
        <span>
          {data[fieldName]}
        </span>
      </div>
    );
  }

  const onSavePrice = (e) => {
    if (e.keyCode !== 13)
      return;

    setFormData({ ...editRowData, price: e.target.value });
  }

  const discountRenderer = (data, fieldName) => {
    if (editRowData && editRowData._id === data._id && fieldName === editFieldName) {
      setTimeout(() => {
        if (discountElement && discountElement.current)
          discountElement.current.focus();
      }, 100, discountElement);

      return (
        <div>
          <input type="text"
                 ref={discountElement}
                 className="input-textbox"
                 defaultValue={data[fieldName]}
                 onKeyDown={(e) => onSaveDiscount(e)}
                 onBlur ={() => setEditRowData(null)}
          />
        </div>
      );
    }

    return (
      <div>
        <span>
          {data[fieldName]}
        </span>
      </div>
    );
  }

  const onSaveDiscount = (e) => {
    if (e.keyCode !== 13)
      return;

    setFormData({ ...editRowData, discount: e.target.value });
  }

  const isActiveRenderer = (data, fieldName) => {
    if (editRowData && editRowData._id === data._id && fieldName === editFieldName) {
      return (
        <div>
          <Switch
            ref={isActiveElement}
            checked={editRowData.isActive}
            onChange={onSaveIsActive}
            onBlur ={() => setEditRowData(null)} />
        </div>
      );
    }

    return (
      <div>
        <span>
          <Switch
            disabled
            checked={data.isActive}
            onChange={onIsActiveChange} />
        </span>
      </div>
    );
  }

  const onSaveIsActive = (isActive) => {
    setFormData({ ...editRowData, isActive: isActive });
  }

  const onDecOrder = (id) => {
    const { order: currentOrder } = productList.find((each) => each._id === id);
    
    if (!currentOrder || currentOrder === 1)
      return;

    onChangeOrder(id, currentOrder, -1);
  }

  const onIncOrder = (id) => {
    const { order: currentOrder } = productList.find((each) => each._id === id);
    
    if (!currentOrder)
      return;

    onChangeOrder(id, currentOrder, 1);
  }

  const onChangeOrder = (id, order, direction) => {
    changeOrder({
      id,
      order,
      direction
    })
      .then(() => {
        getProducts();
      })
      .catch(() => {
        setTimeout(() => {
          setErrMessage('Could not change order!');
          swal("Opps!", "Could not change order!", "warning").then(r => {});
        }, 2000);
      });    
  }

  /* const Dropdown = (props) => {
    const content = useMemo(() => {
      return (
        <DropdownTreeSelect
          data={props.data}
          onChange={onChangeCategory}
          className="dropdown-tree-select"
        />
      )
    }, [props.data]);

    return content;
  } */

  return (
    <React.Fragment>
      <Head title="Product List"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h3" page>
                List of Product
              </BlockTitle>
              <BlockDes className="text-soft">
                <p>You have total {productList.length} products.</p>
              </BlockDes>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>

        <Block className="product-block">
          <div className="container-fluid">
            <div className="row">
              <div className="col col-12 col-md-12 col-lg-12">
                <DataTable className="card-stretch">
                  <div className="card-inner position-relative card-tools-toggle">
                    <div className="card-title-group">
                      <div className="card-tools mr-n1">
                        <ul className="btn-toolbar gx-1">
                          <button className="btn btn-primary" onClick={() => openAddNewModal()}>
                            Add New Product
                          </button>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <DataTableBody compact>
                    <DataTableHead>
                      <DataTableRow size="lg">
                        <span className="sub-text">Product ID</span>
                      </DataTableRow>
                      {availableCategoryList.length > 0
                        && availableCategoryList.map((item) => {
                          return (
                            <DataTableRow size="lg" key={item._id}>
                              <span className="sub-text">{item.label}</span>
                            </DataTableRow>
                          )
                        })
                      }
                      <DataTableRow size="lg">
                        <span className="sub-text">Qty</span>
                      </DataTableRow>
                      <DataTableRow size="lg">
                        <span className="sub-text">Price</span>
                      </DataTableRow>
                      <DataTableRow size="lg">
                        <span className="sub-text">Discount</span>
                      </DataTableRow>
                      <DataTableRow size="lg">
                        <span className="sub-text">Total Price</span>
                      </DataTableRow>
                      <DataTableRow size="lg">
                        <span className="sub-text">Active</span>
                      </DataTableRow>
                      <DataTableRow size="lg">
                        <span className="sub-text">Change Order</span>
                      </DataTableRow>
                      <DataTableRow size="lg">
                        <span className="sub-text"></span>
                      </DataTableRow>
                    </DataTableHead>
                    {currentItems.length > 0
                      ? currentItems.map((item) => {
                          return (
                            <DataTableItem key={item._id}>
                              <DataTableRow size="lg">
                                <span>{item._id}</span>
                              </DataTableRow>
                              {availableCategoryList.length > 0
                                && availableCategoryList.map((categoryItem) => {
                                  return (
                                    <DataTableRow
                                      key={categoryItem._id}
                                      size="lg"
                                      renderer={categoryRenderer(categoryItem, item)}
                                      onClick={(ev) => {
                                        ev.preventDefault();
                                        ev.stopPropagation();
                                        onEditField(item, categoryItem.label);
                                      }}
                                    />
                                  )
                                })
                              }
                              <DataTableRow
                                size="lg"
                                renderer={qtyRenderer(item, 'qty')}
                                onClick={(ev) => {
                                  ev.preventDefault();
                                  ev.stopPropagation();
                                  onEditField(item, 'qty');
                                }}
                              />
                              <DataTableRow
                                size="lg"
                                renderer={priceRenderer(item, 'price')}
                                onClick={(ev) => {
                                  ev.preventDefault();
                                  ev.stopPropagation();
                                  onEditField(item, 'price');
                                }}
                              />
                              <DataTableRow
                                size="lg"
                                renderer={discountRenderer(item, 'discount')}
                                onClick={(ev) => {
                                  ev.preventDefault();
                                  ev.stopPropagation();
                                  onEditField(item, 'discount');
                                }}
                              />
                              <DataTableRow size="lg">
                                <span>{item.totalPrice}</span>
                              </DataTableRow>
                              <DataTableRow
                                size="lg"
                                renderer={isActiveRenderer(item, 'isActive')}
                                onClick={(ev) => {
                                  ev.preventDefault();
                                  ev.stopPropagation();
                                  onEditField(item, 'isActive');
                                }}
                              />
                              <DataTableRow size="lg">
                                <div className="change-order-icons-container">
                                  <Icon
                                    name="arrow-up"
                                    onClick={(ev) => {
                                      ev.preventDefault();
                                      ev.stopPropagation();
                                      onDecOrder(item._id);
                                    }}
                                  >
                                  </Icon>
                                  <Icon
                                    name="arrow-down"
                                    onClick={(ev) => {
                                      ev.preventDefault();
                                      ev.stopPropagation();
                                      onIncOrder(item._id);
                                    }}
                                  >
                                  </Icon>
                                </div>
                              </DataTableRow>
                              <DataTableRow size="lg">
                                <Icon
                                  name="trash"
                                  onClick={(ev) => {
                                    ev.preventDefault();
                                    ev.stopPropagation();
                                    onRemoveProduct(item._id);
                                  }}
                                >
                                </Icon>
                              </DataTableRow>
                            </DataTableItem>
                          );
                        })
                      : null}
                  </DataTableBody>
                  <div className="card-inner">
                    {currentItems.length > 0 ? (
                      <PaginationComponent
                        itemPerPage={itemPerPage}
                        totalItems={productList.length}
                        paginate={paginate}
                        currentPage={currentPage}
                      />
                    ) : (
                      <div className="text-center">
                        <span className="text-silent">No data found</span>
                      </div>
                    )}
                  </div>
                </DataTable>
              </div>
            </div>
          </div>
        </Block>

        <Modal isOpen={modal.add} toggle={() => setModal({ add: false })} className="modal-dialog-centered" size="lg">
          <ModalBody>
            <a
              href="#cancel"
              onClick={(ev) => {
                ev.preventDefault();
                onFormCancel();
              }}
              className="close"
            >
              <Icon name="cross-sm"></Icon>
            </a>
            <div className="p-2">
              <h5 className="title">Add New Product</h5>
              <div className="mt-4">
                <Form className="row pt-2 gy-4" onSubmit={handleSubmit(onSubmitForm)}>
                  <input type="hidden" value={formData._id} name="_id" />
                  {availableCategories.length > 0
                    && availableCategories.map((item) => {
                      return (
                        <Col md="12" key={item._id}>
                          <FormGroup>
                            <label className="form-label">{item.label}</label>
                            <TreeDropdown data={item.children} onChange={onChangeCategory} />
                            {errors.category && <span className="invalid">{errors.category.message}</span>}
                          </FormGroup>
                        </Col>
                      )
                    })
                  }                
                  <Col md="12">
                    <FormGroup>
                      <label className="form-label">Qty</label>
                      <input
                        className="form-control"
                        type="number"
                        name="qty"
                        min="1"
                        defaultValue={formData.qty}
                        placeholder="Qty"
                        ref={register({ required: "This field is required", min: 1 })}
                        onChange={(e) => onInputChange(e)}
                      />
                      {errors.qty && <span className="invalid">{errors.qty.message}</span>}
                    </FormGroup>
                  </Col>
                  <Col md="12">
                    <FormGroup>
                      <label className="form-label">Price</label>
                      <input
                        className="form-control"
                        type="number"
                        name="price"
                        min="0"
                        defaultValue={formData.price}
                        placeholder="Price"
                        ref={register({ required: "This field is required" })}
                        onChange={(e) => onInputChange(e)}
                      />
                      {errors.price && <span className="invalid">{errors.price.message}</span>}
                    </FormGroup>
                  </Col>
                  <Col md="12">
                    <FormGroup>
                      <label className="form-label">Discount</label>
                      <input
                        className="form-control"
                        type="number"
                        name="discount"
                        min="0"
                        defaultValue={formData.discount}
                        placeholder="Discount"
                        ref={register({ required: "This field is required" })}
                        onChange={(e) => onInputChange(e)}
                      />
                      {errors.discount && <span className="invalid">{errors.discount.message}</span>}
                    </FormGroup>
                  </Col>
                  <Col md="12">
                    <FormGroup>
                      <label className="form-label">Total Price</label>
                      <input
                        className="form-control"
                        type="number"
                        name="totalPrice"
                        min="0"
                        readOnly
                        defaultValue={formData.totalPrice}
                        placeholder="Total Price"
                      />
                      {errors.totalPrice && <span className="invalid">{errors.totalPrice.message}</span>}
                    </FormGroup>
                  </Col>
                  <Col md="12">
                    <FormGroup className="is-active-form-group">
                      <label className="form-label is-active-label">Is Active</label>
                      <Switch onChange={onIsActiveChange} checked={formData.isActive} />
                    </FormGroup>
                  </Col>
                  <Col size="12">
                    {message && message !== '' && (
                      <div className="alert alert-light" role="alert">
                        {message}
                      </div>
                    )}
                    {errMessage && errMessage !== '' && (
                      <div className="alert alert-error" role="alert">
                        {errMessage}
                      </div>
                    )}
                  </Col>
                  <Col size="12">
                    <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                      <li>
                        <Button color="primary" size="md" type="submit">
                          Add Product
                        </Button>
                      </li>
                      <li>
                        <a
                          href="#cancel"
                          onClick={(ev) => {
                            ev.preventDefault();
                            onFormCancel();
                          }}
                          className="link link-light"
                        >
                          Cancel
                        </a>
                      </li>
                    </ul>
                  </Col>
                </Form>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </Content>
    </React.Fragment>
  );
};
export default ProductList;
